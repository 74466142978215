import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import { Totak_Supply, Mint, Burn } from "./Web3USDM/Methods";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const columns = [
  { id: "name", label: "Type", minWidth: 170, align: "center" },
  { id: "code", label: "Amount", minWidth: 100, align: "center" },
  {
    id: "population",
    label: "Time",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Hash",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData(
    "Mint",
    1623534.4865198713,
    "8/30/2022, 11:19:53 AM",
    "0xd0c445cd9e06911e299239464531de5dad71c7849f5ef9d8cbc4a6854dc87d8d"
  ),
  createData(
    "Burn",
    -76299.59195874631,
    "8/31/2022, 10:23:36 PM",
    "0xb5c0c93ca223388f4531344470a5fbb8f851ee75144463c3c490be4bb2655fe2"
  ),
];

const error = (msg) => toast.error(msg);
const sucess = (msg) => toast.success(msg);

export default function Usdmmintertable({
  url,
  gcsusdm,
  gcsusd,
  lastmmk,
  currentmmk,
  totalS,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [toBurnMint, setToBurnMint] = useState(undefined);
  const [fixedMarketCap, setFixedMarketCap] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const init = async () => {
      Calculation();
      getTabledata();
    };
    init();
    // setInterval(async() => {
    //   Calculation();
    // }, 5000);
  }, [gcsusdm, gcsusd]);

  const Calculation = async () => {
    // const govt = await axios
    //   .get(`${url}/values`)
    //   .then(function (response) {
    //     return response.data[0].govt;
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // const ResultOLDGCStoUSDm = (LastMMk * closeprice) / govt;
    // const ResultOLDPRICERATIO = closeprice / ResultOLDGCStoUSDm.toFixed(5);
    // const ResultNEWGCStoUSDm = (currentMMk * closeprice) / govt;

    const fixedMcap = 755030 * gcsusd;

    let newSupply = fixedMcap / Number(gcsusd / gcsusdm).toFixed(5);

    newSupply = newSupply - Number(totalS) ;

    setToBurnMint(newSupply);

    setFixedMarketCap(fixedMcap);
  };

  const Mint_token = async (amount) => {
    try {
      const data1 = await Mint(amount);
      console.log(data1);
      if (data1.status) {
        // const data = await Totak_Supply();
        sucess("Token Minted Successfully");
        dataFill("Mint", amount, data1.transactionHash);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Burn_token = async (amount) => {
    try {
      const data1 = await Burn(amount);
      console.log(data1);
      if (data1.status) {
        // const data = await Totak_Supply();
        sucess("Token Burned Successfully");
        dataFill("Burn", amount, data1.transactionHash);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dataFill = async (method, amount, hash) => {
    var d = new Date().toLocaleString();
    axios
      .post(`${url}/makehistory`, {
        id: 0,
        Method: method,
        Amount: amount,
        Date: d,
        Hash: hash,
      })
      .then((res) => {
        console.log(res);
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [tabledata, setTableData] = useState([]);

  const getTabledata = async () => {
    axios
      .post(`${url}/historybyid`, {
        id: 0,
      })
      .then((res) => {
        console.log(res);
        res.data.reverse();
        setTableData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Container maxWidth="md">
        <Box>
          <Grid container spacing={2} className="d-flex justify-center">
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <Box className="mmmk">
                <h6 className="marketmmk">MMK price 30 Mins ago</h6>
                <Box className="mmkprice">{currentmmk}</Box>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <Box className="mmmk">
                <h6 className="marketmmk">Current MMK price</h6>
                <Box className="mmkprice">{lastmmk}</Box>
              </Box>
            </Grid>
          </Grid>
          {toBurnMint > 0 ? (
            <span className="suggestionMint fw-bold  p-2">
              Suggested Mint {Number(toBurnMint).toFixed(0)} USDm
            </span>
          ) : (
            <span className="fw-bold suggestionBurn p-2">
              Suggested Burn {Number(toBurnMint).toFixed(0)} USDm
            </span>
          )}
          <Box className="mint-burn-button">
            {toBurnMint > 0 ? (
              <button
                className="className-mint"
                onClick={() => Mint_token(Number(toBurnMint))}
              >
                Mint
              </button>
            ) : (
              <button
                className="className-burn"
                onClick={() => Burn_token(Number(toBurnMint))}
              >
                Burn
              </button>
            )}
          </Box>
        </Box>
      </Container>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabledata &&
              tabledata.map((res) => {
                return (
                  <TableRow hover role="checkbox">
                    <>
                      <TableCell>{res.Method}</TableCell>
                      <TableCell>{res.Amount}</TableCell>
                      <TableCell>{res.Date}</TableCell>
                      <TableCell>{res.Hash}</TableCell>
                    </>
                  </TableRow>
                );
              })}
            {/* <TableRow hover role="checkbox">
              {tabledata &&
                tabledata.map((res) => {
                  return (
                    <>
                      <TableCell>{res.Method}</TableCell>
                      <TableCell>{res.Amount}</TableCell>
                      <TableCell>{res.Date}</TableCell>
                      <TableCell>{res.Hash}</TableCell>
                    </>
                  );
                })}
            </TableRow> */}
          </TableBody>
        </Table>
        <Toaster />
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
