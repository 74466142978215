import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import { MDBIcon } from "mdb-react-ui-kit";
import { Box, Container } from "@mui/system";
import toast, { Toaster } from "react-hot-toast";
import { AiOutlineSwap } from "react-icons/ai";
import {
  getGCSBal,
  getUSDM,
  getUSDMBal,
  getUSDTBal,
  getXAUSBal,
  NewSwapToken1,
  NewSwapToken2,
  NewSwapToken3,
  baseTime,
  priceDuration,
  dailyLimitReached,
} from "../Web3/Web3";
import axios from "axios";
import { Co2Sharp } from "@mui/icons-material";

const notify = () => toast("Swap Success");
const warning = (msg) => toast.error(msg);

const usdm = "0x08ab7e5c08cc0d78589fc506c35ea9c2520a86bc";
const gcs = "0x3d2bb1f7ab5d64C3917DbE03d37843421A42e0cD";
const xaus = "0x50ea0dffe399a706edc310f55c658e8b0ec27981";
const usdt = "0x55d398326f99059fF775485246999027B3197955";

export default function Swap({
  gcsusdm,
  gcstousd,
  xaustousdm,
  account,
  xaustousd,
  url,
}) {

  const [swap, setSwap] = useState(true);
  const [swap2, setSwap2] = useState(true);
  const [swap3, setSwap3] = useState(true);
  const [swap4, setSwap4] = useState(true);
  const [gcsBalance, setGcsBalance] = useState(0);
  const [usdmBalance, setUsdmBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [amount2, setAmount2] = useState(0);
  const [amount3, setAmount3] = useState(0);
  const [amount4, setAmount4] = useState(0);
  const [usdtbal, setUsdtbalance] = useState(0);
  const [xausbal, setXausbalance] = useState(0);
  const [basetime, setBaseTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [setDailyLimitStatus, setLimitStatus] = useState(false);
  const current = new Date().getTime() / 1000;

  useEffect(() => {
    const init = async () => {
      const gcsbal = await getGCSBal();
      // console.log("GCS balance ", gcsbal);
      setGcsBalance(
        isNaN(Number(gcsbal).toFixed(5)) ? 0 : Number(gcsbal).toFixed(5)
      );
      const usdmbal = await getUSDMBal();
      setUsdmBalance(
        isNaN(Number(usdmbal).toFixed(5)) ? 0 : Number(usdmbal).toFixed(5)
      );
      const xausbal = await getXAUSBal();
      setXausbalance(
        isNaN(Number(xausbal).toFixed(5)) ? 0 : Number(xausbal).toFixed(5)
      );
      const usdtbal = await getUSDTBal();
      setUsdtbalance(
        isNaN(Number(usdtbal).toFixed(5)) ? 0 : Number(usdtbal).toFixed(5)
      );

      const time = await baseTime();
      setBaseTime(Number(time));

      const dura = await priceDuration();
      setDuration(Number(dura));

      const dailyLimitStatus = await dailyLimitReached();
      setLimitStatus(dailyLimitStatus);

      // console.log("Base", dura, time);
    };
    setInterval(() => {
      init();
    }, 5000);
  }, [account]);

  const Swap = async () => {
    let isrun = false;
    isrun = await axios.get(url).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    });
    if (!isrun) {
      warning("Somthing went wrong!");
      return true;
    }

    if (basetime + duration < current) {
      warning("Price is not updated, please try again later");
      return true;
    }

    if (setDailyLimitStatus) {
      warning("You have exhausted your daily limit!");
      return true;
    }

    const data = await NewSwapToken1(swap, amount);
    if (data.status) {
      notify();
      const gcsbal = await getGCSBal();
      setGcsBalance(Number(gcsbal).toFixed(5));
      const usdmbal = await getUSDMBal();
      setUsdmBalance(Number(usdmbal).toFixed(5));
    }
  };

  const Swap2 = async () => {
    let isrun = false;
    isrun = await axios.get(url).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    });
    if (!isrun) {
      warning("Somthing went wrong");
      return true;
    }

    if (basetime + duration < current) {
      warning("Price is not updated, please try again later");
      return true;
    }

    if (setDailyLimitStatus) {
      warning("You have exhausted your daily limit!");
      return true;
    }

    const data = await NewSwapToken2(swap3, amount3);
    if (data.status) {
      notify();
      const xausbal = await getXAUSBal();
      setXausbalance(Number(xausbal).toFixed(5));
      const usdtbal = await getUSDTBal();
      setUsdtbalance(Number(usdtbal).toFixed(5));
    }
  };

  const Swap3 = async () => {
    let isrun = false;
    isrun = await axios.get(url).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    });
    if (!isrun) {
      warning("Somthing went wrong");
      return true;
    }

    if (basetime + duration < current) {
      warning("Price is not updated, please try again later");
      return true;
    }

    if (setDailyLimitStatus) {
      warning("You have exhausted your daily limit!");
      return true;
    }
    
    const data = await NewSwapToken3(swap4, amount4, gcstousd, gcs, usdt);
    if (data.status) {
      notify();
      const gcsbal = await getGCSBal();
      setXausbalance(Number(gcsbal).toFixed(5));
      const usdtbal = await getUSDTBal();
      setUsdtbalance(
        isNaN(Number(usdtbal).toFixed(5)) ? 0 : Number(usdtbal).toFixed(5)
      );
    }
  };

  const maxforgcsusdm = () => {
    if (swap) {
      setAmount(gcsBalance);
    } else {
      setAmount(usdmBalance);
    }
  };

  const maxforgcsusdm2 = () => {
    if (swap3) {
      setAmount3(xausbal);
    } else {
      setAmount3(usdtbal);
    }
  };

  const maxforgcsusdm3 = () => {
    if (swap4) {
      setAmount4(gcsBalance);
    } else {
      setAmount4(usdtbal);
    }
  };

  // console.log(account)
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <MDBCard className="mx-3">
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  GCS <AiOutlineSwap /> USDT
                </MDBCardTitle>
                <MDBCardSubTitle
                  className="text-center"
                  style={{
                    color: "#787373",
                    borderBottom: "1px solid #a5a0a0",
                  }}
                ></MDBCardSubTitle>
                {
                  <>
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap4 ? "GCS" : "USDT"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap4 ? gcsBalance : usdtbal}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        type="number"
                        value={amount4}
                        onChange={(e) => setAmount4(e.target.value)}
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                      <Typography
                        className="max-button"
                        onClick={() => maxforgcsusdm3()}
                      >
                        {" "}
                        MAX
                      </Typography>
                    </Box>
                    <MDBIcon
                      fas
                      icon="arrows-alt-v"
                      className="swap-icon"
                      onClick={() => setSwap4(!swap4)}
                    />
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap4 ? "USDT" : "GCS"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap4 ? usdtbal : gcsBalance}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        disabled={true}
                        value={
                          swap4 ? amount4 * gcstousd : amount4 * (1 / gcstousd)
                        }
                        type="number"
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                    </Box>
                  </>
                }
                <Box className="swap">
                  <Typography
                    className="swap-button"
                    sx={{ margin: "auto" }}
                    onClick={() => Swap3()}
                  >
                    SWAP
                  </Typography>
                </Box>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <MDBCard className="gcsswap mx-3">
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  GCS <AiOutlineSwap /> USDM
                </MDBCardTitle>
                <MDBCardSubTitle
                  className="text-center"
                  style={{
                    color: "#787373",
                    borderBottom: "1px solid #a5a0a0",
                  }}
                ></MDBCardSubTitle>
                {
                  <>
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap ? "GCS" : "USDM"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap ? gcsBalance : usdmBalance}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                      <Typography
                        className="max-button"
                        onClick={() => maxforgcsusdm()}
                      >
                        {" "}
                        MAX
                      </Typography>
                    </Box>

                    <MDBIcon
                      fas
                      icon="arrows-alt-v"
                      className="swap-icon"
                      onClick={() => setSwap(!swap)}
                    />
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap ? "USDM" : "GCS"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap ? usdmBalance : gcsBalance}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        disabled={true}
                        type="number"
                        value={swap ? amount * gcsusdm : amount * (1 / gcsusdm)}
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                    </Box>
                  </>
                }
                <Box className="swap">
                  <Typography
                    className="swap-button"
                    sx={{ margin: "auto" }}
                    onClick={() => Swap()}
                  >
                    SWAP
                  </Typography>
                </Box>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <MDBCard className="mx-3">
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  XAUS <AiOutlineSwap /> USDT
                </MDBCardTitle>
                <MDBCardSubTitle
                  className="text-center"
                  style={{
                    color: "#787373",
                    borderBottom: "1px solid #a5a0a0",
                  }}
                ></MDBCardSubTitle>
                {
                  <>
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap3 ? "XAUS" : "USDT"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap3 ? xausbal : usdtbal}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        type="number"
                        value={amount3}
                        onChange={(e) => setAmount3(e.target.value)}
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                      <Typography
                        className="max-button"
                        onClick={() => maxforgcsusdm2()}
                      >
                        {" "}
                        MAX
                      </Typography>
                    </Box>
                    <MDBIcon
                      fas
                      icon="arrows-alt-v"
                      className="swap-icon"
                      onClick={() => setSwap3(!swap3)}
                    />
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap3 ? "USDT" : "XAUS"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap3 ? usdtbal : xausbal}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        disabled={true}
                        value={
                          swap3
                            ? amount3 * xaustousd
                            : amount3 * (1 / (xaustousd * 1.1))
                        }
                        type="number"
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                    </Box>
                  </>
                }
                <Box className="swap">
                  <Typography
                    className="swap-button"
                    sx={{ margin: "auto" }}
                    onClick={() => Swap2()}
                  >
                    SWAP
                  </Typography>
                </Box>
              </MDBCardBody>
            </MDBCard>
          </Grid>
        </Grid>
        <Toaster />
      </Container>
      {/* <Container maxWidth="md">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <MDBCard className="mx-3">
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  XAUS <AiOutlineSwap /> USDT
                </MDBCardTitle>
                <MDBCardSubTitle
                  className="text-center"
                  style={{
                    color: "#787373",
                    borderBottom: "1px solid #a5a0a0",
                  }}
                ></MDBCardSubTitle>
                {
                  <>
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap3 ? "XAUS" : "USDT"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap3 ? xausbal : usdtbal}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        type="number"
                        value={amount3}
                        onChange={(e) => setAmount3(e.target.value)}
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                      <Typography
                        className="max-button"
                        onClick={() => maxforgcsusdm2()}
                      >
                        {" "}
                        MAX
                      </Typography>
                    </Box>
                    <MDBIcon
                      fas
                      icon="arrows-alt-v"
                      className="swap-icon"
                      onClick={() => setSwap3(!swap3)}
                    />
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap3 ? "USDT" : "XAUS"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap3 ? usdtbal : xausbal}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        disabled={true}
                        value={
                          swap3
                            ? amount3 * xaustousd
                            : amount3 * (1 / xaustousd)
                        }
                        type="number"
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                    </Box>
                  </>
                }
                <Box className="swap">
                  <Typography
                    className="swap-button"
                    sx={{ margin: "auto" }}
                    onClick={() => Swap2()}
                  >
                    SWAP
                  </Typography>
                </Box>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <MDBCard className="mx-3">
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  USDM <AiOutlineSwap /> XAUS
                </MDBCardTitle>
                <MDBCardSubTitle
                  className="text-center"
                  style={{
                    color: "#787373",
                    borderBottom: "1px solid #a5a0a0",
                  }}
                ></MDBCardSubTitle>
                {
                  <>
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap2 ? "USDM" : "XAUS"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap2 ? usdmBalance : xausbal}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        type="number"
                        value={amount2}
                        onChange={(e) => setAmount2(e.target.value)}
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                      <Typography
                        className="max-button"
                        onClick={() => maxforgcsusdm2()}
                      >
                        {" "}
                        MAX
                      </Typography>
                    </Box>
                    <MDBIcon
                      fas
                      icon="arrows-alt-v"
                      className="swap-icon"
                      onClick={() => setSwap2(!swap2)}
                    />
                    <Box className="position-relative">
                      <Box className="title-area">
                        <Typography
                          sx={{ margin: "10px 0px", fontWeight: "500" }}
                        >
                          {swap2 ? "XAUS" : "USDM"}
                        </Typography>
                        {account ? (
                          <Typography>
                            Balance : {swap2 ? xausbal : usdmBalance}
                          </Typography>
                        ) : (
                          <Typography>wallet not connected</Typography>
                        )}
                      </Box>
                      <MDBInput
                        id="form1"
                        disabled={true}
                        value={
                          swap2
                            ? amount2 * (1 / xaustousdm)
                            : amount2 * xaustousdm
                        }
                        type="number"
                        placeholder="0.0"
                        style={{ padding: "30px 20px" }}
                      />
                    </Box>
                  </>
                }
                <Box className="swap">
                  <Typography
                    className="swap-button"
                    sx={{ margin: "auto" }}
                    onClick={() => Swap2()}
                  >
                    SWAP
                  </Typography>
                </Box>
              </MDBCardBody>
            </MDBCard>
          </Grid>
        </Grid>
      </Container> */}
      {/* <Container maxWidth="md">
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <MDBCard className="mx-3">
            <MDBCardBody>
              <MDBCardTitle
                className="text-center"
                style={{ fontSize: "15px", color: "#F300F9" }}
              >
                THIS SWAP IS ON ETHEREUM NETWORK
              </MDBCardTitle>
              <MDBCardTitle
                className="text-center"
                style={{ fontWeight: "bold" }}
              >
                USDM <AiOutlineSwap /> ETH
              </MDBCardTitle>
              <MDBCardSubTitle
                className="text-center"
                style={{
                  color: "#787373",
                  borderBottom: "1px solid #a5a0a0",
                }}
              ></MDBCardSubTitle>
              {
                <>
                  <Box className="position-relative">
                    <Box className="title-area">
                      <Typography
                        sx={{ margin: "10px 0px", fontWeight: "500" }}
                      >
                        {swap4 ? "USDM" : "ETH"}
                      </Typography>
                      {account ? (
                        <Typography>
                          Balance : {swap4 ? xausbal : usdtbal}
                        </Typography>
                      ) : (
                        <Typography>wallet not connected</Typography>
                      )}
                    </Box>
                    <MDBInput
                      id="form1"
                      type="number"
                      value={amount3}
                      onChange={(e) => setAmount3(e.target.value)}
                      placeholder="0.0"
                      style={{ padding: "30px 20px" }}
                    />
                    <Typography
                      className="max-button"
                      onClick={() => maxforgcsusdm3()}
                    >
                      {" "}
                      MAX
                    </Typography>
                  </Box>
                  <MDBIcon
                    fas
                    icon="arrows-alt-v"
                    className="swap-icon"
                    onClick={() => setSwap4(!swap4)}
                  />
                  <Box className="position-relative">
                    <Box className="title-area">
                      <Typography
                        sx={{ margin: "10px 0px", fontWeight: "500" }}
                      >
                        {swap4 ? "ETH" : "USDM"}
                      </Typography>
                      {account ? (
                        <Typography>
                          Balance : {swap4 ? usdtbal : xausbal}
                        </Typography>
                      ) : (
                        <Typography>wallet not connected</Typography>
                      )}
                    </Box>
                    <MDBInput
                      id="form1"
                      disabled={true}
                      value={
                        swap4 ? amount3 * xaustousd : amount3 * (1 / xaustousd)
                      }
                      type="number"
                      placeholder="0.0"
                      style={{ padding: "30px 20px" }}
                    />
                  </Box>
                </>
              }
              <Box className="swap">
                <Typography
                  className="swap-button"
                  sx={{ margin: "auto" }}
                  onClick={() => Swap3()}
                >
                  SWAP
                </Typography>
              </Box>
            </MDBCardBody>
          </MDBCard>
        </Grid>
      </Container> */}
    </>
  );
}
