import Web3 from "web3/dist/web3.min.js";
// import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { swapabi, swapaddress, tokenBalance } from "./GCS-to-USDM-abi";
import { usdtswapabi, usdtswapaddress } from "./GCS-to-USDT-abi";
import { Staking, stakingAddress } from "./Staking";
import { nftabi, nftaddress } from "./NFT";

const abi = [
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const provider = new WalletConnectProvider({
  infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
  rpc: {
    56: "https://bsc-dataseed1.ninicoin.io",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    1: "https://mainnet.infura.io/v3/",
    // ...
  },
});

export const contract_BSC_MAAL_token_supply =async(bsctoken, maaltoken)=>{
  try {
    const bsc = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.binance.org'));
    const maal = new Web3('https://node3-mainnet.maalscan.io');

    const bsc_Contract = new bsc.eth.Contract(abi, bsctoken);
    const maal_Contract = new maal.eth.Contract(abi, maaltoken);
  
    const bcssupply = await bsc_Contract.methods.totalSupply().call();
    const maalsupply = await maal_Contract.methods.totalSupply().call();
    const totalsupply = (Number(bcssupply)/10**18)  + Number(maalsupply)/10**18
   

    return totalsupply
  } catch (error) {
    console.log("Both error ", error)
  }
}

let web3 = new Web3(window.ethereum);
let externalweb3 = null;

export const WalletConnectlogin = async () => {
  try {
    const data = await provider.enable();
    web3 = new Web3(provider);
    externalweb3 = provider;
  } catch (error) {
    console.log(error);
  }
};

export const webProvide = () => {
  return externalweb3;
};

export const webinternal = () => {
  return web3;
};

const usdm = "0x08ab7e5c08cc0d78589fc506c35ea9c2520a86bc";
const gcs = "0x3d2bb1f7ab5d64c3917dbe03d37843421a42e0cd";
const xaus = "0x50ea0dfFE399A706EdC310f55c658e8B0eC27981";
const stakingToken = "0x3B55C9725338253F5B2bc428B4B6A63A40Aa1994";

export const login = async () => {
  web3 = new Web3(window.ethereum);
  externalweb3 = window.ethereum;
  const data = await window.ethereum.enable();
  return data[0];
};

export const DissconnectWallet = async () => {
  await provider.disconnect();
  web3 = null;
};



export const getUserAddress = async () => {
  try {
    const address = await web3.eth.getAccounts();
    return address[0];
  } catch (error) {
    console.log(error);
  }
};

export const getContract = async (abi, address) => {
  try {
    const Contract = new web3.eth.Contract(abi, address);
    return Contract;
  } catch (error) {
    console.log(error);
  }
};

export const towie = async (amount) => {
  try {
    const number = await web3.utils.toWei(amount.toString(), "ether");
    return number;
  } catch (error) {
    console.log(error);
  }
};

export const GetChainId = async () => {
  try {
    const id = await web3.eth.getChainId();
    return id;
  } catch (error) {}
};

export const XAUs_Totak_Supply = async () => {
  try {
    const contract = new web3.eth.Contract(abi, xaus);
    const data = await contract.methods.totalSupply().call();
    // console.log("contract",contract)
    return Number(data / 10 ** 18);
  } catch (error) {
    console.log(error)
  }
};

export const USDM_Totak_Supply = async () => {
  try {
    const contract = new web3.eth.Contract(abi, usdm);
    const data = await contract.methods.totalSupply().call();
    return Number(data / 10 ** 18);
  } catch (error) {
    console.log(error)
  }
};

export const GCS_Totak_Supply = async () => {
  try {
    const contract = new web3.eth.Contract(abi, gcs);
    const data = await contract.methods.totalSupply().call();
    // console.log("contract",contract)
    return Number(data / 10 ** 18);
  } catch (error) {
    console.log(error)
  }
};

export const SwapToken = async (tab, amount, ratio, usdm, gcs) => {
  try {
    const a = await towie(amount);
    const r = await towie(ratio);
    const b = await towie(1 / ratio);
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    console.log(a, r, b);
    if (tab) {
      const isApprove = await Allow(gcs);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          .swapGCSTOUSDM(a, r)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        const data2 = await Approve(gcs);
        const data = await contract.methods
          .swapGCSTOUSDM(a, r)
          .send({ from: await getUserAddress() });
        return data;
      }
    } else {
      const isApprove = await Allow(usdm);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          .swapUSDMTOGCS(a, b)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        await Approve(usdm);
        const data = await contract.methods
          .swapUSDMTOGCS(a, b)
          .send({ from: await getUserAddress() });
        return data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

// export const SwapToken2 = async(tab,amount,ratio,usdm,xaus)=>{
//     try {
//         const a = await towie(amount);
//         const r = await towie(ratio);
//         const b = await towie(1/ratio);
//         const contract = new web3.eth.Contract(swapabi, swapaddress);

//         if(tab){
//             const isApprove = await Allow(usdm);
//             if(Number(isApprove)>0){
//                 console.log(a,b)
//                 const data = await contract.methods.swapUSDMTOXAUS(a,b).send({from:await getUserAddress()});
//                 return data;
//             }
//             else{
//                 console.log(a,b)
//                 const data2 = await Approve(usdm);
//                 const data = await contract.methods.swapUSDMTOXAUS(a,b).send({from:await getUserAddress()});
//                 return data;
//             }
//         }
//         else{
//             const isApprove = await Allow(xaus);
//             if(Number(isApprove)>0){
//                 console.log(a,r)
//               const data = await contract.methods.swapXAUSTOUSDM(a,r).send({from:await getUserAddress()});
//               return data;
//             }
//             else{
//                 console.log(a,r)
//                 await Approve(xaus);
//                 const data = await contract.methods.swapXAUSTOUSDM(a,r).send({from:await getUserAddress()});
//                 return data;
//             }
//         }
//     } catch (error) {
//         console.log(error)
//     }

// }

// export const SwapToken3 = async(tab,amount,ratio,xaus,usdt)=>{
//     try {
//         const a = await towie(amount);
//         const r = await towie(ratio);
//         const b = await towie(1/ratio);
//         const contract = new web3.eth.Contract(swapabi, swapaddress);

//         if(tab){
//             //xaus to usdt
//             const isApprove = await Allow(xaus);
//             if(Number(isApprove)>0){
//                 console.log(a,r,tab)
//                 const data = await contract.methods.swapXAUSTOUSDT(a,r).send({from:await getUserAddress()});
//                 return data;
//             }
//             else{
//                 console.log(a,r)
//                 const data2 = await Approve(xaus);
//                 const data = await contract.methods.swapXAUSTOUSDT(a,r).send({from:await getUserAddress()});
//                 return data;
//             }
//         }
//         else{
//             //usdt to xaus
//             const isApprove = await Allow(usdt);
//             if(Number(isApprove)>0){
//                 console.log(a,b,tab)
//               const data = await contract.methods.swapUSDTTOXAUS(a,b).send({from:await getUserAddress()});
//               return data;
//             }
//             else{
//                 console.log(a,b)
//                 await Approve(usdt);
//                 const data = await contract.methods.swapUSDTTOXAUS(a,b).send({from:await getUserAddress()});
//                 return data;
//             }
//         }
//     } catch (error) {
//         console.log(error)
//     }

// }

// export const SwapToken4 = async(tab,amount,ratio,gcs,usdt)=>{
//   try {
//       const a = await towie(amount);
//       const r = await towie(ratio);
//       const b = await towie(1/ratio);
//       const contract = new web3.eth.Contract(usdtswapabi, usdtswapaddress);
//       const us = await contract.methods.USDT().call();
//       const gc = await contract.methods.GCS().call();
//       console.log(us,gc)
//       if(tab){
//           //GCS to usdt
//           const isApprove = await AllowUSDT(gcs);
//           if(Number(isApprove)>0){

//               const data = await contract.methods.swapGCSTOUSDT(a,r).send({from:await getUserAddress()});
//               return data;
//           }
//           else{
//               // console.log(a,r)
//               const data2 = await ApproveUSDT(gcs);
//               const data = await contract.methods.swapGCSTOUSDT(a,r).send({from:await getUserAddress()});
//               return data;
//           }
//       }
//       else{
//           //usdt to GCS

//           const isApprove = await AllowUSDT(usdt);
//           console.log(isApprove)
//           if(Number(isApprove)>0){
//               // console.log(a,b,tab)
//             const data = await contract.methods.swapUSDTTOGCS(a,b).send({from:await getUserAddress()});
//             return data;
//           }
//           else{
//               // console.log(a,b)
//               await ApproveUSDT(usdt);
//               const data = await contract.methods.swapUSDTTOGCS(a,b).send({from:await getUserAddress()});
//               return data;
//           }
//       }
//   } catch (error) {
//       console.log(error)
//   }

// }

// export const getTokenBalancegcs =async(address)=>{
//     try {
//         const contract = new web3.eth.Contract(tokenBalance, address);
//         const data = await contract.methods.balanceOf(await getUserAddress()).call();
//         return data/10**18;
//     } catch (error) {
//         console.log(error)
//     }
// }

// export const getTokenBalanceusdm =async(address)=>{
//     try {
//         const contract = new web3.eth.Contract(tokenBalance, address);
//         const data = await contract.methods.balanceOf(await getUserAddress()).call();
//         return data/10**18;
//     } catch (error) {
//         console.log(error)
//     }
// }

export const Approve = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    const data = await contract.methods
      .approve(
        swapaddress,
        115792089237316195423570985008687907853269984665640564039457584007913129639935n
      )
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Allow = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    const data = await contract.methods
      .allowance(await getUserAddress(), swapaddress)
      .call();
    return data;
  } catch (error) {}
};

export const AllowUSDT = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    console.log("Token address", address);
    const data = await contract.methods
      .allowance(await getUserAddress(), usdtswapaddress)
      .call();
    return data;
  } catch (error) {}
};

export const ApproveUSDT = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    console.log("Token address", address);
    const data = await contract.methods
      .approve(
        usdtswapaddress,
        115792089237316195423570985008687907853269984665640564039457584007913129639935n
      )
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const newAdmin = async (address) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      .setAdmin(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

// export const getAdmin = async()=>{
//     try {
//         const contract = new web3.eth.Contract(swapabi, swapaddress);
//         const data = await contract.methods.admin().call();
//         console.log("Swap admin",data)
//         return data;
//     } catch (error) {
//         console.log(error)
//     }
// }

export const WithdrawEth = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      .recoverLostETH()
      .send({ from: await getUserAddress() })
      .call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Withdrawtoken = async (token, amount) => {
  try {
    const a = await towie(amount);
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      .WithdrawOtherTokens(token, a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const ContractTokenBal = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    const data = await contract.methods.balanceOf(swapaddress).call();
    return Number(data / 10 ** 18).toFixed(4);
  } catch (error) {
    console.log(error);
  }
};

export const ContractTokenBal2 = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    const data = await contract.methods.balanceOf(usdtswapaddress).call();
    return Number(data / 10 ** 18).toFixed(4);
  } catch (error) {
    console.log(error);
  }
};

export const Approveforstaking = async (token) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, token);
    const data = await contract.methods
      .approve(
        stakingAddress,
        115792089237316195423570985008687907853269984665640564039457584007913129639935n
      )
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Allowforstaking = async (token) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, token);
    const data = await contract.methods
      .allowance(await getUserAddress(), stakingAddress)
      .call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const StakingtokenBalance = async () => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, stakingToken);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {}
};
export const transfertoken = async (address, amount, token) => {
  try {
    const a = await towie(amount);
    console.log(a, address, token);
    const contract = new web3.eth.Contract(tokenBalance, token);
    const data = await contract.methods
      .transfer(address, a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {}
};
export const StakeBalace = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    console.log("MY STAKE", data);
    return data / 10 ** 18;
  } catch (error) {}
};

export const totakRewardEarned = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .totalRewardEarn(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {}
};

export const orderIDofReferal = async (address) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.investorOrderIds(address).call();
    return data;
  } catch (error) {}
};

export const pendingrewards = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.withdraw(await getUserAddress()).call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const emergencyaction = async (id) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .emergencyWithdraw(id)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const balanceofstake = async (address) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.balanceOf(address).call();
    console.log("MY STAKE", data);
    return data / 10 ** 18;
  } catch (error) {}
};

export const tokenpending = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.totalRewardPending().call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const tokenDistribute = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.totalRewardsDistribution().call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const orderIDReferrals = async (address) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.investorOrderIds(address).call();
    return data;
  } catch (error) {}
};

export const OrderIDdata = async (id) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.orders(id).call();
    return data;
  } catch (error) {}
};

export const GetPendingRewards = async (id) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.pendingRewards(id).call();
    return data / 10 ** 18;
  } catch (error) {}
};

export const balanceofreferral = async (address) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.balanceOf(address).call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getDetailsfoFirstStakeofuser = async () => {
  try {
    const events = [];
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const ids = await orderID();
    const event = await contract.methods.orders(ids[0]).call();
    return event;
  } catch (error) {
    console.log(error);
  }
};

//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////
//////////////////New Staking//////////////////

export const Addpool = async (
  rewardblock,
  tokem,
  fee,
  penalty,
  reward,
  duration,
  payoutperiod,
  minStakelevel3,
  minStakelevel2,
  minStakelevel1
) => {
  try {
    const level1 = await towie(minStakelevel1);
    const level2 = await towie(minStakelevel2);
    const level3 = await towie(minStakelevel3);
    const rewardwir = reward * 10;
    console.log("reward", rewardwir);
    // const a = await towie(Number(rewardblock).toFixed(8))
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .addPool(
        rewardblock,
        tokem,
        fee,
        penalty,
        rewardwir,
        duration,
        payoutperiod,
        level3,
        level2,
        level1,
        true
      )
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const editpool = async (
  poolid,
  apy,
  fee,
  duration,
  payoutperiod,
  minStakeAmntEntryLvl,
  minStakeAmntEntryLvl2,
  minStakeAmntEntryLvl1,
  tab
) => {
  try {
    const level1 = await towie(minStakeAmntEntryLvl1);
    const level2 = await towie(minStakeAmntEntryLvl2);
    const level3 = await towie(minStakeAmntEntryLvl);
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .setPool(poolid, tab)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOwner = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.owner().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const poollength = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.poolLength().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const assetSymbol = async (token) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, token);
    const data = await contract.methods.symbol().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Stake = async (amount, id, lptoken) => {
  try {
    const a = await towie(amount);
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const allow = await Allowforstaking(lptoken);
    console.log("pool id", id, allow, lptoken);
    if (Number(allow) > 0) {
      const data = await contract.methods
        .deposit(id, a)
        .send({ from: await getUserAddress() });
      return data;
    } else {
      const data2 = await Approveforstaking(lptoken);
      if (data2.status) {
        const data = await contract.methods
          .deposit(id, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const PendingRewards = async (id, address) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.pendingRewards(id, address).call();
    return data / 10 ** 18;
  } catch (error) {}
};

export const unstake = async (orderid, pid) => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .withdraw(orderid, pid)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getallTokenBalancegcs = async (address) => {
  try {
    const contract = new web3.eth.Contract(tokenBalance, address);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};
export const totallocked = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.totalLockedUpRewards().call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const totalstakedinContract = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.totalStaked().call();
    return data / 10 ** 18;
  } catch (error) {}
};

export const totalpools = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.poolLength().call();
    return data;
  } catch (error) {}
};

export const userTotalStake = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const totalpool = await totalpools();
    let total = 0;
    for (let i = 0; i < totalpool; i++) {
      const user = await contract.methods
        .userInfo(i, await getUserAddress())
        .call();
      total = total + Number(user[0]);
    }
    return total / 10 ** 18;
  } catch (error) {}
};

export const orderID = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods
      .investorOrderIds(await getUserAddress())
      .call();
    return data;
  } catch (error) {}
};

export const getDetails = async () => {
  const events = [];
  const contract = new web3.eth.Contract(Staking, stakingAddress);
  const ids = await orderID();
  console.log("ID array", ids);
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const event = await contract.methods.orders(id).call();
    event.id = id;
    events.push(event);
  }
  return events;
};

//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////

//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////
//////////////////NFT//////////////////

export const batchmintforpublic = async (id) => {
  try {
    const a = await towie(0.01 * id.length);
    console.log(id);
    const contract = new web3.eth.Contract(nftabi, nftaddress);
    const data = await contract.methods
      .batchmintPublic(await getUserAddress(), id)
      .send({ from: await getUserAddress(), value: a });
    return data;
  } catch (error) {}
};
export const mintforpublic = async (id) => {
  try {
    const a = await towie(0.01);
    const contract = new web3.eth.Contract(nftabi, nftaddress);
    const data = await contract.methods
      .mintPublic(await getUserAddress(), id)
      .send({ from: await getUserAddress(), value: a });
    return data;
  } catch (error) {}
};

export const batchmintforadmin = async (id) => {
  try {
    const a = await towie(0.041 * id.length);
    console.log(id);
    const contract = new web3.eth.Contract(nftabi, nftaddress);
    const data = await contract.methods
      .batchmintByOwner(await getUserAddress(), id)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {}
};

export const ETHrecover = async () => {
  try {
    const contract = new web3.eth.Contract(nftabi, nftaddress);
    const data = await contract.methods
      .recoverETH()
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {}
};

export const getAdminNFT = async () => {
  try {
    const contract = new web3.eth.Contract(nftabi, nftaddress);
    const data = await contract.methods._owner().call();
    return data;
  } catch (error) {
    // console.log("Error is",error) 
  }
};

export const getAdmin = async () => {
  try {
    const contract = new web3.eth.Contract(Staking, stakingAddress);
    const data = await contract.methods.owner().call();
    return data;
  } catch (error) {
    console.log(error)
  }
};




//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////

//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////
//////////////////NEW SWAP//////////////////

export const getGCS = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.GCS().call();
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const getUSDM = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.USDM().call();
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const getUSDT = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.USDT().call();
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const getXAUS = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.XAUS().call();
    return data;
  } catch (error) {
    // console.log(error)
  }
};

export const getGCSContractBal = async (address) => {
  try {
    const gcs = await getGCS();
    console.log("GCS ", gcs)
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods.balanceOf(swapaddress).call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getXAUSContractBal = async (address) => {
  try {
    const gcs = await getXAUS();
    console.log("XAUS ", gcs)
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods.balanceOf(swapaddress).call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getUSDTContractBal = async (address) => {
  try {
    const usdt = await getUSDT();
    console.log("usdt ", usdt)
    const contract = new web3.eth.Contract(tokenBalance, usdt);
    const data = await contract.methods.balanceOf(swapaddress).call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getUSDMContractBal = async (address) => {
  try {
    const gcs = await getUSDM();
    console.log("gcs ", gcs)
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods.balanceOf(swapaddress).call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getGCSBal = async (address) => {
  try {
    const gcs = await getGCS();
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getXAUSBal = async (address) => {
  try {
    const gcs = await getXAUS();
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getUSDTBal = async (address) => {
  try {
    const gcs = await getUSDT();
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const getUSDMBal = async (address) => {
  try {
    const gcs = await getUSDM();
    const contract = new web3.eth.Contract(tokenBalance, gcs);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return data / 10 ** 18;
  } catch (error) {
    console.log(error);
  }
};

export const totalUSDTfee = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.feesCollectedUSDT().call();
    return Number(data / 10 ** 18).toFixed(6);
  } catch (error) {
    console.log(error);
  }
};

export const totalGCSfee = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.feesCollectedGCS().call();
    return Number(data / 10 ** 18).toFixed(6);
  } catch (error) {
    console.log(error);
  }
};

export const totalUSDMfee = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.feesCollectedUSDM().call();
    return Number(data / 10 ** 18).toFixed(6);
  } catch (error) {
    console.log(error);
  }
};

export const totalXAUSfee = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.feesCollectedXAUS().call();
    return Number(data / 10 ** 18).toFixed(6);
  } catch (error) {
    console.log(error);
  }
};

export const baseTime = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.lastUpdateTimeForBasePairs().call();
    return Number(data);
  } catch (error) {
    console.log(error);
  }
};

export const totalGCSswap = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.totalGCSSwapped().call();
    return Number(data / 10 ** 18).toFixed(0);
  } catch (error) {
    console.log(error);
  }
};

export const totalUSDMswap = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.totalUSDMSwapped().call();
    return Number(data / 10 ** 18).toFixed(0);
  } catch (error) {
    console.log(error);
  }
};

export const totalXAUSswap = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.totalXAUSSwapped().call();
    return Number(data / 10 ** 18).toFixed(0);
  } catch (error) {
    console.log(error);
  }
};

export const totalUSDTswap = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.totalUSDTSwapped().call();
    return Number(data / 10 ** 18).toFixed(0);
  } catch (error) {
    console.log(error);
  }
};

export const newFee = async (gcsusdt, gcsusdm, xaususdt) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._setFees(gcsusdt * 10, gcsusdm * 10, xaususdt * 10)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const coinmakingcharge = async (val) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._updateXAUSCoinMakingCharges(val)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePrice = async (xaususd, mmk, gcsusdt) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._updatePrices(xaususd, mmk, gcsusdt)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const govtRate = async (rate) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._setMMKGovtRate(rate)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const ChangeNextTime = async (rate) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._setUpdatePriceDuration(rate)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const ChangeOperator = async (rate) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._setOperator(rate)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const ChangeStatus = async (GCSUSDTstatus, GCSUSDMstatus, XAUSUSDTstatus) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._setSwapStatus(GCSUSDTstatus, GCSUSDMstatus, XAUSUSDTstatus)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const SwapStatus = async (rate) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.swapActive().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const dailyLimitReached = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods._dailyLimitReached(await getUserAddress()).call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const priceDuration = async (rate) => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.priceUpdateDuration().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOprator = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.operator().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getSwapOwner = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods.owner().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const _recoverBNB = async () => {
  try {
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._recoverBNB()
      .send({ from: await getUserAddress() })
      .call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const _withdrawOtherTokens = async (token, amount) => {
  try {
    const a = await towie(amount);
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    const data = await contract.methods
      ._withdrawOtherTokens(token, a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const _deposite = async (token, amount) => {
  try {
    const a = await towie(amount);
    const contract = new web3.eth.Contract(tokenBalance, token);
    const data = await contract.methods.transfer(swapaddress, a).send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const NewSwapToken1 = async (tab, amount) => {
  try {
    const a = await towie(amount);
    console.log(a)
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    if (tab) {
      const gcs = await getGCS();
      const isApprove = await Allow(gcs);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          ._gcsUsdmSwap(gcs, a)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        const data2 = await Approve(gcs);
        const data = await contract.methods
          ._gcsUsdmSwap(gcs, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    } else {
      const usdm = await getUSDM();
      const isApprove = await Allow(usdm);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          ._gcsUsdmSwap(usdm, a)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        await Approve(usdm);
        const data = await contract.methods
          ._gcsUsdmSwap(usdm, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const NewSwapToken2 = async (tab, amount) => {
  try {
    const a = await towie(amount);
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    if (tab) {
      const token1 = await getXAUS();
      console.log("XAUS to USDT", token1, a);
      const isApprove = await Allow(token1);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          ._xausUsdtSwap(token1, a)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        const data2 = await Approve(token1);
        const data = await contract.methods
          ._xausUsdtSwap(token1, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    } else {
      const token2 = await getUSDT();
      console.log("USDT to XAUS", token2, a);
      const isApprove = await Allow(token2);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          ._xausUsdtSwap(token2, a)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        await Approve(token2);
        const data = await contract.methods
          ._xausUsdtSwap(token2, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const NewSwapToken3 = async (tab, amount) => {
  try {
    const a = await towie(amount);
    const contract = new web3.eth.Contract(swapabi, swapaddress);
    if (tab) {
      const gcs = await getGCS();
      const isApprove = await Allow(gcs);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          ._gcsUsdtSwap(gcs, a)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        const data2 = await Approve(gcs);
        const data = await contract.methods
          ._gcsUsdtSwap(gcs, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    } else {
      const usdm = await getUSDT();
      const isApprove = await Allow(usdm);
      if (Number(isApprove) > 0) {
        const data = await contract.methods
          ._gcsUsdtSwap(usdm, a)
          .send({ from: await getUserAddress() });
        return data;
      } else {
        await Approve(usdm);
        const data = await contract.methods
          ._gcsUsdtSwap(usdm, a)
          .send({ from: await getUserAddress() });
        return data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};
