import { Container } from "@mui/material";
import { MDBCardTitle } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Adminlogin() {
  const [inputPass, setInputPass] = useState("");
  const [inputUser, setInputUser] = useState("");
  const [error, setError] = useState(null);
  const [credentials, setCredentials] = useState({
    username: "COMBINEGCEX",
    password: "COMBINE@123",
  });

  const navigate = useNavigate();

  const login = (e) => {
    e.preventDefault();
    if (
      inputUser !== credentials.username &&
      inputPass !== credentials.password
    ) {
      setError("please enter correct user pass");
      console.log("please enter correct user pass");
    } else {
      // eslint-disable-next-line no-unused-expressions
      inputUser === credentials.username && inputPass === credentials.password;

      localStorage.setItem("userpass", JSON.stringify(credentials));
      navigate("/admin");
      window.location.reload();
    }
  };

  return (
    <div>
      <Container maxWidth="lg" className="my-5">
        <div>
          <MDBCardTitle className="text-center" style={{ fontWeight: "bold" }}>
            Please Enter Details to Login
          </MDBCardTitle>
        </div>

        {error}

        <form onSubmit={login}>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              Username
            </label>
            <input
              type="text"
              value={inputUser}
              onChange={(e) => setInputUser(e.target.value)}
              className="form-control"
              required
              id="exampleInputPassword1"
              placeholder="Enter username"
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              required
              value={inputPass}
              onChange={(e) => setInputPass(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Enter password"
            />
          </div>

          <button className="addminterbutton3 login-button-admin" type="submit">
            Login
          </button>
        </form>
      </Container>
    </div>
  );
}
