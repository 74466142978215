import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { getOwner } from "../Web3/Web3";
import { Box } from "@mui/system";

export default function AdminNav({ account }) {
  const [showBasic, setShowBasic] = useState(false);
  const [active, setActive] = useState();

  useEffect(() => {
    const init = async () => {
      const own = await getOwner();
      setActive(own);
    };
    init();
  }, []);

  // console.log(account, active, active == account)

  return (
    <MDBNavbar expand="lg" light bgColor="transparent" className="adminnav">
      <MDBContainer fluid>
        {/* <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler> */}
        <Box className="admin-tab-nav">
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
            <>
              <div className="d-flex align-items-center">
                <Link
                  id="link"
                  to="/admin/staker's-detail"
                  className={
                    window.location.pathname === "/admin/staker's-detail"
                      ? "active"
                      : ""
                  }
                >
                  Staker's Details
                </Link>

                <Link
                  id="link2"
                  to="/admin/create-level"
                  className={
                    window.location.pathname === "/admin/create-level"
                      ? "active"
                      : ""
                  }
                >
                  Create Level
                </Link>
                <Link
                  id="link"
                  to="/admin/see-level"
                  className={
                    window.location.pathname === "/admin/see-level"
                      ? "active"
                      : ""
                  }
                >
                  Levels
                </Link>
                <Link
                  id="link3"
                  to="/admin/usdm"
                  className={
                    window.location.pathname === "/admin/usdm" ? "active" : ""
                  }
                >
                  USDM
                </Link>
                <Link
                  id="link"
                  to="/admin/xaus"
                  className={
                    window.location.pathname === "/admin/xaus" ? "active" : ""
                  }
                >
                  XAUS
                </Link>
              </div>
            </>
          </MDBNavbarNav>
        </Box>
      </MDBContainer>
    </MDBNavbar>
  );
}
