import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBadge,
  MDBInput,
  MDBCardSubTitle,
} from "mdb-react-ui-kit";

import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Box } from "@mui/system";

import toast, { Toaster } from "react-hot-toast";

import {
  totalGCSfee,
  totalUSDMfee,
  ContractTokenBal,
  ContractTokenBal2,
  WithdrawEth,
  _recoverBNB,
  totalUSDTfee,
  totalXAUSfee,
  totalGCSswap,
  totalUSDTswap,
  totalUSDMswap,
  totalXAUSswap,
  newAdmin,
  newFee,
  Withdrawtoken,
  _withdrawOtherTokens,
  ETHrecover,
  getXAUSBal,
  getGCSBal,
  getUSDTBal,
  getUSDMBal,
  coinmakingcharge,
  updatePrice,
  govtRate,
  ChangeNextTime,
  ChangeOperator,
  ChangeStatus,
  SwapStatus,
  getSwapOwner,
  getOprator,
  baseTime,
  priceDuration,
  getXAUSContractBal,
  getGCSContractBal,
  getUSDMContractBal,
  getUSDTContractBal,
  getUSDT,
  getXAUS,
  getUSDM,
  getGCS,
  getUserAddress,
  _deposite,
} from "../../Web3/Web3";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import StakingTable from "../StakingTable";
import AdminNav from "../AdminNav";
import { batchmintforadmin } from "./../../Web3/Web3";
import axios from "axios";
import Progressbar from "./Progressbar";

const notify = (msg) => toast(msg);
const warn = (msg) => toast.error(msg);

const usdm = "0x08ab7e5c08cc0d78589fc506c35ea9c2520a86bc";
const gcs = "0x3d2bb1f7ab5d64c3917dbe03d37843421a42e0cd";
const xaus = "0x50ea0dffe399a706edc310f55c658e8b0ec27981";
const usdt = "0x55d398326f99059fF775485246999027B3197955";
const id = "63382cdc3171dfd5d7715948";

export default function Admin({ account, contractadmin, url, url2,  xaustousd }) {
  const current = new Date().getTime() / 1000;
  const [usdmfee, setUsdmfee] = useState(0);
  const [xausfee, setXausfee] = useState(0);
  const [usdtfee, setUsdtfee] = useState(0);
  const [gcsfee, setGcsfee] = useState(0);
  const [usdmswap, setUsdmswap] = useState(0);
  const [xausswap, setXausswap] = useState(0);
  const [usdtswap, setUsdtswap] = useState(0);
  const [gcsswap, setGcsswap] = useState(0);
  const [fee, setFee] = useState(0);
  const [admin, setAdmin] = useState();
  const [user, setUser] = useState(false);
  const [token, setToken] = useState();
  const [tokenamount, setTokenAmount] = useState();
  const [xausbal, setXausbal] = useState(0);
  const [gcsbal, setGcsbal] = useState(0);
  const [usdtbal, setUsdtbal] = useState(0);
  const [usdmbal, setUsdmbal] = useState(0);
  const [withdrawAmount, setWithDrawAmount] = useState(0);
  const [ids, setIDs] = useState();
  const [showerror, setShowerror] = useState(false);
  const [mintedids, setMintedids] = useState([]);
  const [usdt2bal, setUSDT2bal] = useState(0);
  const [gcs2bal, setGCS2bal] = useState(0);
  const [usdt2fee, setUSDT2fee] = useState(0);
  const [gcs2fee, setGCS2fee] = useState(0);

  const [govtrate, setGovtRate] = useState();

  const [mmkprice, setMMkprice] = useState(0);
  const [xauUsd, setXauUsd] = useState(0);
  const [gcsusdt, setGcsUsdt] = useState(0);

  const [coinmaking, setCoinMaking] = useState(0);

  const [gcsusdtfee, setGcsUsdtfee] = useState(0);
  const [gcsusdmfee, setGcsUsdmfee] = useState(0);
  const [xaususdtfee, setXausUsdtfee] = useState(0);
  const [swapstatus, setSwapStatus] = useState(false);

  const [nextTime, setNextTime] = useState();
  const [oprator, setOperator] = useState();

  const [lastoperator, setLastOperator] = useState();
  const [owner, setOwner] = useState();

  const [swapbaseTime, setSwapBaseTime] = useState(0);
  const [swapbaseDuration, setSwapBaseDuration] = useState(0);

  //POPUP FOR WITHDRAWL
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [xau, setXau] = useState(0);
  const [gcsusd, setGcsUsd] = useState();
  const [gcsusdm, setGcsUsdm] = useState(0);
  const [addcurrentmmk, setAddcurrentmmk] = useState(0);
  const [govt, setGOVT] = useState(0);
  const [isDepoiste, setIsdeposite] = useState();

  useLayoutEffect(() => {
    const init = async () => {
      const ope = await getOprator();
      const own = await getSwapOwner();
      const acc = await getUserAddress();
      // console.log(acc)
      if (acc == ope || acc == own) {
        console.log("Running this one", account != ope, account != own);
      } else {
        // window.location.replace("/");
      }
    };
    init();
  }, []);

  useEffect(() => {
    const apicall = () => {
      axios
        .get(`${url}/gcsdetails`)
        .then((res) => {
          // res.data.reverse();
          // console.log(res.data[0]);
          setGcsUsd(Number(res.data.GCS.GCSTOUSDT));
          setXau(Number(res.data.XAUS.XAUTOUSD));
          setGcsUsdm(Number(res.data.GCS.GCSTOUSDM));
          setAddcurrentmmk(Number(res.data.MMK.ADDJUSTMMK));
          setGOVT(Number(res.data.MMK.GOVT));
        })
        .catch(console.error);
    };
    apicall();
  }, [account]);

  useEffect(() => {
    const init = async () => {
      if (contractadmin == account) {
        setUser(true);
      }
      const xausbal = await getXAUSContractBal();
      const gcsbal = await getGCSContractBal();
      const usdmbal = await getUSDMContractBal();
      const usdtbal = await getUSDTContractBal();

      const satust = await SwapStatus();
      setSwapStatus(satust);

      const own = await getSwapOwner();
      const ope = await getOprator();
      setLastOperator(ope.toLowerCase());
      setOwner(own.toLowerCase());
      const usdmf = await totalUSDMfee();
      const gcsf = await totalGCSfee();
      const usdtf = await totalUSDTfee();
      const xausf = await totalXAUSfee();
      const usdms = await totalUSDMswap();
      const usdts = await totalUSDTswap();
      const xauss = await totalXAUSswap();
      const gcss = await totalGCSswap();

      const base = await baseTime();
      const duration = await priceDuration();
      setSwapBaseDuration(Number(duration));
      setSwapBaseTime(Number(base));

      console.log(base, duration);

      // const usdts2f = await totalUSDT2fee();
      // const gcs2f = await totalGCS2fee();
      // setUSDT2fee(usdts2f);
      // setGCS2fee(gcs2f);

      setXausbal(Number(xausbal).toFixed(3));
      setGcsbal(Number(gcsbal).toFixed(3));
      setUsdmbal(Number(usdmbal).toFixed(3));
      setUsdtbal(Number(usdtbal).toFixed(3));
      setGcsfee(gcsf);
      setUsdmfee(usdmf);
      setUsdtfee(usdtf);
      setXausfee(xausf);
      setUsdmswap(usdms);
      setUsdtswap(usdts);
      setXausswap(xauss);
      setGcsswap(gcss);
    };

    init();
  }, [account]);

  useEffect(() => {
    const init = async () => {
      axios.get(`${url2}/nfts`).then((res) => {
        console.log(res);
        setMintedids(res.data[0].ids);
      });
    };
    init();
  }, []);

  const setnewAdmin = async () => {
    const data = await newAdmin(admin);
    if (data.status) {
      notify("New admin set successfully!");
    }
  };

  // console.log(account)

  const setnewfee = async () => {
    const data = await newFee(gcsusdtfee, gcsusdmfee, xaususdtfee);
    if (data.status) {
      notify("Fee updated!");
    }
  };

  const PriceUpdate = async () => {
    const data = await updatePrice(xauUsd, mmkprice, gcsusdt);
    if (data.status) {
      notify("Price updated!");
    }
  };

  const updateGovernmentRate = async () => {
    const data = await govtRate(govtrate);
    if (data.status) {
      notify("Government Rate updated!");
    }
  };

  const MakingCoinCharges = async () => {
    const data = await coinmakingcharge(coinmaking);
    if (data.status) {
      notify("Updated!");
    }
  };

  const updateNextTime = async () => {
    const data = await ChangeNextTime(nextTime);
    if (data.status) {
      notify("Time updated!");
    }
  };

  const [GCSUSDTstatus, setGCSUSDTstatus] = useState(false);
  const [GCSUSDMstatus, setGCSUSDMstatus] = useState(false);
  const [XAUSUSDTstatus, setXAUSUSDTstatus] = useState(false);

  const changeSatatus = async () => {
    if (GCSUSDTstatus != "true" || GCSUSDTstatus != "false") {
      warn("Invalid input");
      return;
    }
    if (GCSUSDMstatus != "true" || GCSUSDMstatus != "false") {
      warn("Invalid input");
      return;
    }
    if (XAUSUSDTstatus != "true" || XAUSUSDTstatus != "false") {
      warn("Invalid input");
      return;
    }
    const data = await ChangeStatus(
      GCSUSDTstatus,
      GCSUSDMstatus,
      XAUSUSDTstatus
    );
    if (data.status) {
      notify("Fee updated!");
    }
  };

  const changeOperator = async () => {
    const data = await ChangeOperator(oprator);
    if (data.status) {
      notify("Fee updated!");
    }
  };

  const reoverEth = async () => {
    const data = await WithdrawEth();
    if (data.status) {
      notify("Success");
    }
  };

  const recoverBNB = async () => {
    const data = await _recoverBNB();
    if (data.status) {
      notify("Success");
    }
  };

  const reoverBNBToken = async () => {
    const data = await Withdrawtoken(token, tokenamount);
    if (data.status) {
      notify("Success");
    }
  };

  const recoverOtherTokens = async (tab) => {
    if (isDepoiste) {
      const data = await _withdrawOtherTokens(token, tokenamount);
      if (data.status) {
        notify("Success");
      }
    } else {
      const data = await _deposite(token, tokenamount);
      if (data.status) {
        notify("Success");
      }
    }
  };

  const reoverETH = async () => {
    const data = await ETHrecover();
    if (data.status) {
      notify("Success");
    }
  };

  const handleId = (e) => {
    const mintid = e.target.value.split(",");
    if (mintid.length > 10) {
      warn("Can not mint more than 10");
    }
    let allFounded = mintid.some((ai) => mintedids.includes(Number(ai)));
    if (allFounded) {
      setShowerror(true);
    } else {
      setShowerror(false);
      setIDs(e.target.value);
    }
  };
  const batchmintDB = async () => {
    const mintid = ids.split(",");
    for (let i = 0; i < mintid.length; i++) {
      mintedids.push(Number(mintid[i]));
    }
    axios
      .post(`${url}/addnft`, {
        id: id,
        nfts: mintedids,
      })
      .then((res) => {
        notify("Minted Successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const batchmint = async () => {
    let isrun = false;
    isrun = await axios.get(url).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    });
    if (!isrun) {
      warn("Something went wrong!");
      return true;
    }
    if (showerror) {
      return true;
    }
    const myArray = ids.split(",");
    const data = await batchmintforadmin(myArray);
    if (data.status) {
      batchmintDB();
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <AdminNav account={account} />
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "20px", padding: "10px 20px" }}
        >
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>GCS Fee Collected</MDBCardTitle>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {(63.742 + Number(gcsfee)).toFixed(3)}
                  <MDBBadge color="success" className="mx-2">
                    {/* <MDBIcon fas icon="chart-line" /> 70.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>XAUS Fee Collected</MDBCardTitle>{" "}
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${Number(usdmmarketcap).toFixed(0)} */}
                  {(14.008 + Number(xausfee)).toFixed(3)}
                  <MDBBadge color="danger" className="mx-2">
                    {/* <MDBIcon fas icon="chart-line" /> 27.02% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>USDM Fee Collected</MDBCardTitle>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${xausmk} */}
                  {(126.73 + Number(usdmfee)).toFixed(3)}
                  <MDBBadge color="danger" className="mx-2">
                    {/* <MDBIcon fas icon="chart-line" /> 9.00% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>USDT Fee Collected</MDBCardTitle>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* {Number(1 / gcsusdm).toFixed(5)} */}
                  {(19.813 + Number(usdtfee)).toFixed(3)}
                  <MDBBadge color="success" className="mx-2">
                    {/* <MDBIcon fas icon="chart-line" /> 70.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>USDT Swapped</MDBCardTitle>{" "}
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${xaustousd} */}
                  {68422 + Number(usdtswap)}
                  <MDBBadge color="success" className="mx-2">
                    {/* <BiLineChart size={20}/> 59.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>USDM Swapped</MDBCardTitle>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${usdmtousdt} */}
                  {179616 + Number(usdmswap)}
                  <MDBBadge color="success" className="mx-2">
                    {/* <BiLineChart size={20}/> 70.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>XAUS Swapped</MDBCardTitle>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${gcstousd} */}
                  {2673 + Number(xausswap)}
                  <MDBBadge color="danger" className="mx-2">
                    {/* <BiLineChartDown size={20}/> 27.02% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>GCS Swapped</MDBCardTitle>{" "}
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* {Number(xaustogcs).toFixed(5)} */}
                  {367 + Number(gcsswap)}
                  <MDBBadge color="success" className="mx-2">
                    {/* <BiLineChart size={20}/> 70.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <Box className="buttonspacing">
                  <MDBCardTitle>USDT Balance</MDBCardTitle>{" "}
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getUSDT());
                      handleShow(true);
                      setIsdeposite(true);
                    }}
                  >
                    withdraw
                  </Button>{" "}
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getUSDT());
                      handleShow(false);
                      setIsdeposite(false);
                    }}
                  >
                    Deposit
                  </Button>{" "}
                </Box>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${xaustousd} */}
                  {usdtbal}{" "}
                  <MDBBadge color="success" className="mx-2">
                    {/* <BiLineChart size={20}/> 59.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <Box className="buttonspacing">
                  <MDBCardTitle>USDM Balance</MDBCardTitle>
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getUSDM());
                      handleShow(true);
                      setIsdeposite(true);
                    }}
                  >
                    withdraw
                  </Button>
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getUSDM());
                      handleShow(false);
                      setIsdeposite(false);
                    }}
                  >
                    Deposit
                  </Button>
                </Box>

                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${usdmtousdt} */}
                  {usdmbal}{" "}
                  <MDBBadge color="success" className="mx-2">
                    {/* <BiLineChart size={20}/> 70.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <Box className="buttonspacing">
                  <MDBCardTitle>XAUS Balance</MDBCardTitle>
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getXAUS());
                      handleShow(true);
                      setIsdeposite(true);
                    }}
                  >
                    withdraw
                  </Button>
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getXAUS());
                      handleShow(false);
                      setIsdeposite(false);
                    }}
                  >
                    Deposit
                  </Button>
                </Box>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* ${gcstousd} */}
                  {xausbal}{" "}
                  <MDBBadge color="danger" className="mx-2">
                    {/* <BiLineChartDown size={20}/> 27.02% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MDBCard>
              <MDBCardBody>
                <Box className="buttonspacing">
                  <MDBCardTitle>GCS Balance</MDBCardTitle>{" "}
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getGCS());
                      handleShow(true);
                      setIsdeposite(true);
                    }}
                  >
                    withdraw
                  </Button>
                  <Button
                    className="withdrawlbutton"
                    onClick={async () => {
                      setToken(await getGCS());
                      handleShow(false);
                      setIsdeposite(false);
                    }}
                  >
                    Deposit
                  </Button>
                </Box>
                <h4
                  style={{
                    fontWeight: " 500",
                    color: "#000000",
                    fontSize: "20px",
                  }}
                >
                  {/* {Number(xaustogcs).toFixed(5)} */}
                  {gcsbal}{" "}
                  <MDBBadge color="success" className="mx-2">
                    {/* <BiLineChart size={20}/> 70.32% */}
                  </MDBBadge>
                </h4>
              </MDBCardBody>
            </MDBCard>
          </Grid>
        </Grid>
        {/* <Grid
          container
          spacing={2}
          sx={{ marginTop: "20px", padding: "10px 20px" }}
        >
          
        </Grid> */}

        {/* <Grid
          container
          spacing={2}
          sx={{ marginTop: "20px", padding: "10px 20px" }}
        >
          
        </Grid> */}


        {/* UPDATE SECTION */}

        <Grid
          container
          spacing={2}
          sx={{ marginTop: "20px", padding: "10px 20px" }}
        >
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MDBCard className="cardheightarea">
              <MDBCardBody>
                <h3 className="totalsupplystyle">
                  XAU TO USD : <strong>$ {xau}</strong>
                </h3>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MDBCard className="cardheightarea">
              <MDBCardBody>
                <h3 className="totalsupplystyle">
                  Effective MMK Rate:{" "}
                  <strong>
                    {(
                      (1 - Number(gcsusd / gcsusdm)) * Number(addcurrentmmk) +
                      govt
                    ).toFixed(0)}
                  </strong>
                </h3>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MDBCard className="cardheightarea">
              <MDBCardBody>
                <h3 className="totalsupplystyle">
                  GCS TO USDT : <strong>$ {gcsusd}</strong>
                  {/* GCS TO USDT: {gcstousd.toFixed(7)} */}
                </h3>
              </MDBCardBody>
            </MDBCard>
          </Grid>
        </Grid>

        {/* Swap 2 Contract */}

        {account != contractadmin ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3 margintop">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    SET NEW FEE
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <p>GCS TO USDT</p>
                        <MDBInput
                          id="form1"
                          type="number"
                          onChange={(e) => setGcsUsdtfee(e.target.value)}
                          placeholder="0.0"
                          style={{ padding: "30px 20px" }}
                        />
                        <br />
                        <p>GCS TO USDM</p>
                        <MDBInput
                          id="form1"
                          type="number"
                          onChange={(e) => setGcsUsdmfee(e.target.value)}
                          placeholder="0.0"
                          style={{ padding: "30px 20px" }}
                        />
                        <br />
                        <p>XAUS TO USDM</p>
                        <MDBInput
                          id="form1"
                          type="number"
                          onChange={(e) => setXausUsdtfee(e.target.value)}
                          placeholder="0.0"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => setnewfee()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              {/* PROGRESS BAR */}
              <Box> {/* <Progressbar /> */}</Box>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody
                  id={swapbaseDuration + swapbaseTime < current ? `` : ""}
                >
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    UPDATE PRICE
                    {/* <p
                      className="timeremaining"
                      style={{ textAlign: "center" }}
                    >
                      {swapbaseDuration + swapbaseTime < current
                        ? `Please update the price`
                        : ""}
                    </p> */}
                    <br />
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <p>XAU TO USD</p>
                        <MDBInput
                          id="form1"
                          type="number"
                          onChange={(e) => setXauUsd(e.target.value)}
                          placeholder="XAU2 = 175810 for 1758.10"
                          style={{ padding: "30px 20px" }}
                        />
                        <br />
                        <p>MMK P2P RATE</p>
                        <MDBInput
                          id="form1"
                          type="number"
                          onChange={(e) => setMMkprice(e.target.value)}
                          placeholder="mmkP2PRate = 3090 = 3090"
                          style={{ padding: "30px 20px" }}
                        />
                        <br />
                        <p>GCS TO USDT</p>
                        <MDBInput
                          id="form1"
                          type="number"
                          onChange={(e) => setGcsUsdt(e.target.value)}
                          placeholder="GCS 2 USDT = 11735 for 11.735"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => PriceUpdate()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    SET NEW ADMIN
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => setAdmin(e.target.value)}
                          type="text"
                          placeholder="address"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => setnewAdmin()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    SET OPERATOR
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => setOperator(e.target.value)}
                          type="text"
                          placeholder="address"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => changeOperator()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    UPDATE PRICE DURATION
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => setNextTime(e.target.value)}
                          type="Number"
                          placeholder="Seconds"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => updateNextTime()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    UPDATE STATUS
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    // <>
                    //   <Box className="position-relative">
                    //     <Box className="title-area"></Box>
                    //     <MDBInput
                    //       id="form1"
                    //       onChange={(e) => setNextTime(e.target.value)}
                    //       type="datetime-local"
                    //       placeholder="time"
                    //       style={{ padding: "30px 20px" }}
                    //     />
                    //   </Box>
                    // </>
                  }
                  <Box className="swap">
                    GCS to USDT
                    <MDBInput
                      id="form1"
                      onChange={(e) => setGCSUSDTstatus(e.target.value)}
                      type="text"
                      placeholder="false"
                      style={{ padding: "30px 20px" }}
                    />
                    <br />
                    GCS to USDT
                    <MDBInput
                      id="form1"
                      onChange={(e) => setGCSUSDMstatus(e.target.value)}
                      type="text"
                      placeholder="true"
                      style={{ padding: "30px 20px" }}
                    />
                    <br />
                    GCS to USDT
                    <MDBInput
                      id="form1"
                      onChange={(e) => setXAUSUSDTstatus(e.target.value)}
                      type="text"
                      placeholder="false"
                      style={{ padding: "30px 20px" }}
                    />
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => changeSatatus()}
                    >
                      {swapstatus ? "PAUSE" : "RESUME"}
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    UPDATE GOVT RATE
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        {/* <p></p> */}
                        <MDBInput
                          id="form1"
                          onChange={(e) => setGovtRate(e.target.value)}
                          type="text"
                          placeholder=""
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => updateGovernmentRate()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    XAUS COIN MAKING CHARGES
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        {/* <p></p> */}
                        <MDBInput
                          id="form1"
                          onChange={(e) => setCoinMaking(e.target.value)}
                          type="text"
                          placeholder=""
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => MakingCoinCharges()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    RECOVER ASSETS
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => setToken(e.target.value)}
                          type="text"
                          placeholder="Token address"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                      <br />
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => setTokenAmount(e.target.value)}
                          type="number"
                          placeholder="Amount"
                          style={{ padding: "30px 20px" }}
                        />
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => recoverOtherTokens()}
                    >
                      SUBMIT
                    </Typography>
                  </Box>
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => recoverBNB()}
                    >
                      RECOVER ONLY BNB
                    </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} xl={6}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
               BATCH MINT
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                    <Box style={{margin:"1rem 0",fontSize:"15px"}}>Input NFT number you wish to mint. If you are minting multiple then put all the numbers separated by a comma.</Box>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => handleId(e)}
                          type="text"
                          placeholder="Amount"
                          style={{ padding: "30px 20px" }}
                        />
                        {showerror ? <p className="waring">ID is allready minted</p> : ""}
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => batchmint()}
                    >
                      MINT
                    </Typography>
                  </Box>
                  <Box className="swap">
                  <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => reoverETH()}
                    >
                      RECOVER ETH
                  </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid> */}
          </Grid>
        ) : (
          ""
        )}
        <Toaster />
        <Modal
          show={show}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Specify Amount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="row withdraw g-0">
              <div className="col-auto">
                <label for="inputAoumnt" className="visually-hidden"></label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword2"
                  placeholder="Amount"
                  onChange={(e) => setTokenAmount(e.target.value)}
                />
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  className="withdrawlbutton"
                  onClick={() => recoverOtherTokens()}
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
