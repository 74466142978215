import * as React from "react";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import {
  Burn,
  Mint,
  Totak_Supply,
  Checking_Balance,
  Checking_Minter,
} from "./Web3XAUS/Methods";

const error = (msg) => toast.error(msg);
const sucess = (msg) => toast.success(msg);

const columns = [
  { id: "name", label: "Type", minWidth: 170, align: "center" },
  { id: "code", label: "Amount", minWidth: 100, align: "center" },
  {
    id: "population",
    label: "Time",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Hash",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}


export default function Xausminterpage({
  reservGCS,
  gcstousd,
  xau,
  url,
  totalS,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [toburnmint, setToburnmint] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isminter, setIsminter] = useState(false);

  useEffect(() => {
    const inti = async () => {
      getTabledata();
      const bal = await Checking_Balance(  );
      console.log("Balance", bal);
      setBalance(bal);
      const ismint = await Checking_Minter();
      setIsminter(ismint);
    };
    inti();
    API_CALl(totalS);
  }, [xau, totalS]);

  const API_CALl = async (supply) => {
    // const data = await Totak_Supply();
   
    await Calculation(supply);
  };

  const dataFill = async (method, amount, hash) => {
    var d = new Date().toLocaleString();
    await axios
      .post(`${url}/makehistory`, {
        id: 1,
        Method: method,
        Amount: amount,
        Date: d,
        Hash: hash,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Calculation = async (totalsupply) => {
    const changer = (reservGCS * gcstousd) / ((xau / 31.1025) * 0.425 * 1.1);
    const amountToChange = changer - totalsupply;
    setToburnmint(amountToChange);
    
  };

  const Mint_token = async (amount) => {
    try {
      const data1 = await Mint(amount);
      console.log(data1);
      if (data1.status) {
       
        sucess("Token Minted Successfully");
        dataFill("Mint", amount, data1.transactionHash);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Burn_token = async (amount) => {
    try {
      if (balance <= 0) {
        error("Low Balance");
        return true;
      }
      const data1 = await Burn(amount);
      console.log("Burn from Master: ", data1);
      if (data1.status) {
        sucess("Token Burned Successfully");
        dataFill("Burn", amount, data1.transactionHash);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [tabledata, setTableData] = useState([]);

  const getTabledata = async () => {
    axios
      .post(`${url}/historybyid`, {
        id: 1,
      })
      .then((res) => {
   
        res.data.reverse();
        setTableData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Container maxWidth="md">
        <Box>
          <Grid container spacing={2} className="d-flex justify-center">
            <Grid item xl={12} lg={12} md={12}>
              <Box className="rescuerarea">
                <Box className="rescuerbutton my-2">
                  <input
                    type="text"
                    placeholder="0"
                    value={toburnmint}
                    className={toburnmint > 0 ? "rescuerinput" : "rescuerinput2"}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="mint-burn-button">
            {toburnmint > 0 ? (
              <button
                className="className-mint"
                onClick={() => Mint_token(toburnmint)}
              >
                Mint
              </button>
            ) : (
              <button
                className="className-burn"
                onClick={() => Burn_token(toburnmint)}
              >
                Burn
              </button>
            )}
            {/* {toburnmint > 0 ? (
              <button
                className="className-burn"
                onClick={() => Burn_token(toburnmint)}
              >
                Burn
              </button>
            ) : (
              <button
                className="className-mint"
                onClick={() => Mint_token(toburnmint)}
              >
                Mint
              </button>
            )} */}
          </Box>
        </Box>
      </Container>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabledata &&
              tabledata.map((res) => {
                return (
                  <TableRow hover role="checkbox">
                    <TableCell>{res.Method}</TableCell>
                    <TableCell>{res.Amount}</TableCell>
                    <TableCell>{res.Date}</TableCell>
                    <TableCell>{res.Hash}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Toaster />
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
