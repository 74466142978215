import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { MDBIcon } from "mdb-react-ui-kit";
import { Container } from "@mui/system";
import {
  FaTelegram,
  FaTwitter,
  FaDiscord,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa";

import pdf from "../pdf/XAUS Smart Contract Audit Final.pdf";

export default function Footer() {
  return (
    <>
      <Box className="gcexfooter">
        <Container maxWidth="xxl">
          <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={6} sm={12}>
              <Box>
                <ul>
                  <h3 className="footer-name">Useful Links</h3>
                  <li>
                    <a
                      href="https://www.gcex.lt/"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      Goldchain Exchange
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://earn.gcex.lt/"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      DeFi Platform
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://www.gcbtrust.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      GCB Trust
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://bank.gcex.lt/log-in"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      GCB Login
                    </a>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12}>
              <Box>
                <ul>
                  <h3 className="footer-name">Audit</h3>
                  <li>
                    <a
                      href="https://www.goldchainex.com/_files/ugd/5b76bc_8ecd39897bb94be5b26037b4b5b58f7c.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      GCS Audit
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.goldchainex.com/_files/ugd/5b76bc_1dee8962f2cd4cb2a83ec64f80f4154d.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      USDM Audit
                    </a>
                  </li>
                  <li>
                    <a
                      href={pdf}
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      XAUS Audit
                    </a>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12}>
              <Box>
                <ul>
                  <h3 className="footer-name">Reserves</h3>
                  <li>
                    <a
                      href="https://dxsale.app/app/v3_3/dxlockview?id=0&add=0x3d2bb1f7ab5d64C3917DbE03d37843421A42e0cD&type=tokenlock&chain=BSC"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      USDM Reserve
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinksale.finance/pinklock/record/1030579?chain=BSC"
                      target="_blank"
                      rel="noreferrer"
                      className="gcs-audit"
                    >
                      XAUS Reserve
                    </a>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12}>
              <div className="footersocial">
                <h3 className="footer-name">Social Links</h3>
              </div>
              <div className="social-links">
                <Box className="sociallinksarea">
                  <a
                    href="https://t.me/GoldchainExchangeCommunity"
                    target="_blank"
                    className="socialarea"
                    rel="noreferrer"
                  >
                    <FaTelegram size={27} />
                  </a>
                  <a
                    href="https://discord.com/invite/QNNZGD2Zfa"
                    target="_blank"
                    className="socialarea"
                    rel="noreferrer"
                  >
                    <FaDiscord size={27} />
                  </a>
                  <a
                    href="https://twitter.com/GcexNft"
                    target="_blank"
                    className="socialarea"
                    rel="noreferrer"
                  >
                    <FaTwitter size={27} />
                  </a>
                  <a
                    href="https://www.instagram.com/gcexnft/"
                    target="_blank"
                    className="socialarea"
                    rel="noreferrer"
                  >
                    <FaInstagram size={27} />
                  </a>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    className="socialarea"
                    rel="noreferrer"
                  >
                    <FaFacebook size={27} />
                  </a>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Box>
            <p paragraph className="text-center">
              <MDBIcon fas icon="copyright" />
              Platform By Gctech
            </p>
          </Box>

          {/* COMMENTED CODE */}

          {/* Goldchain Exchange
          DeFi Platform
          GCB Trust
          GCB Bank */}

          {/* <Typography  paragraph  className="text-center my-2">Designed & Developed BY KWS</Typography> */}
          {/*  */}
          {/* <Box className="footer-area">
          <Typography variant="h6" className="gcsaudit">
            <Link
              href="https://www.goldchainex.com/_files/ugd/5b76bc_8ecd39897bb94be5b26037b4b5b58f7c.pdf"
              target="_blank"
              className="gcs-audit"
            >
              GCS AUDIT
            </Link>
          </Typography>
          <Typography variant="h6" className="gcsaudit">
            <Link
              href="https://www.goldchainex.com/_files/ugd/5b76bc_1dee8962f2cd4cb2a83ec64f80f4154d.pdf"
              target="_blank"
              className="gcs-audit"
            >
              USDM AUDIT
            </Link>
          </Typography>
        </Box> */}
        </Container>
      </Box>
    </>
  );
}
