import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Container } from "@mui/system";
import { Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import AdminNav from "../AdminNav";
import "./usdm.css";
import axios from "axios";
import Usdmmintertable from "./Usdmmintertable";
import Usdmmasterminter from "./Usdmmasterminter";
import { Button, Form } from "react-bootstrap";
import Xausminterpage from "./Xausminterpage";
import Xausmasterminter from "./Xausmasterminter";
import toast, { Toaster } from "react-hot-toast";
import {
  isContractPaused,
  PauseMintBurn,
  rescuToken,
  resumeMintBurn,
  changeBlacklister,
  changeMasterMinter,
  changePauser,
  changeRescuer,
  Totak_Supply,
  blacklistaddress,
  unblacklistaddress,
} from "./Web3XAUS/Methods";
import { USD_TEST_NET } from "./Web3XAUS/Address";
import { contract_BSC_MAAL_token_supply } from "../../Web3/Web3";

//BELOW FUNCTION IS FOR TABS THAT ARE STARTS FROM LINE NO. 124 MINTER , MASTER MINTER , PAUSER, RESCUER, BLACKLIST, ONWER , CALCULATE

const error = (msg) => toast.error(msg);
const sucess = (msg) => toast.success(msg);
const reservGCS = 160000;
// const url = "https://gcs.ap.ngrok.io"; // no use

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Xauspage({ account, xaustousd, url,  xausmk, xausSupply }) {
  const [gcstousd, setGcstousd] = useState(0);
  const [xau, setXau] = useState(0);
  const [xaususdm, setXausUSdm] = useState(0);
  const [usdmusdt, setUsdmusdt] = useState(0);
  const [xausgcs, setXausgcs] = useState(0);
  const [xaususd, setXaususd] = useState(0);
  useEffect(() => {
    const init = async () => {
      let headersXau = {
        "x-access-token": "goldapi-3etxdtl90ttr5o-io",
        "Content-Type": "application/json",
      };

      let xauOptions = {
        url: "https://www.goldapi.io/api/XAU/USD",
        method: "GET",
        headers: headersXau,
      };

      axios.request(xauOptions).then(function (response) {
        // console.log("Gold price",response.data)
        setXau(response.data.price);
      });

      axios
        .get(`${url}/gcsdetails`)
        .then((res) => {
          setGcstousd(Number(res.data.GCS.GCSTOUSDT));
        })
        .catch(console.error);

      axios.get(`${url}/gcsdetails`).then((res) => {
          // res.data.reverse();
       
          setXausUSdm(Number(res.data.XAUS.XAUSTOUSDM).toFixed(4));
          setUsdmusdt(Number(res.data.XAUS.USDM2USDT).toFixed(4));
          setXausgcs(Number(res.data.XAUS.XAUSTOGCS).toFixed(4));
          setXaususd(Number(res.data.XAUS.XAUSTOUSD).toFixed(4));
        })
        .catch(console.error);
    };
    init();
  }, []);
  // this state is for (MINTER , MASTER MINTER , PAUSER, RESCUER, BLACKLIST, ONWER , CALCULATE) these tabs
  const [value, setValue] = React.useState(0);

  // STATES FOR XAUS RESCUER TAB

  const [rescuertokenaddress, setRescuerTokenAddress] = useState();
  const [rescuerreciveraddress, setRescuerReciverAddress] = useState();
  const [resucervalue, setRescuerValue] = useState(0);

  // STATES FOR XAUS BLACKLIST TAB

  const [addblacklistaddress, setAddBlackListAddress] = useState();
  const [removeblacklisaddress, setRemoveBlackListAddress] = useState();

  // STATES FOR XAUS ONWER TAB

  const [pauseraddress, setPauserAddress] = useState();
  const [blacklistaddress, setBlackListAddress] = useState();
  const [rescueraddress, setRescuerAddress] = useState();
  const [masterminteraddress, setMasterMinterAddress] = useState();

  // STATES FOR XAUS CALCULATOR

  const [gcssupply, setGcsSupply] = useState();
  const [usdmsupply, setUsdmSupply] = useState();
  const [usdtsupply, setUsdtSupply] = useState();
  const [usdcsupply, setUsdcSupply] = useState();

  const [contractpaused, setContractPaused] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pause = async () => {
    try {
      const data = await PauseMintBurn();
      if (data.status) {
        sucess("Paused Successfully");
        const paused = await isContractPaused();
        setContractPaused(paused);
      } else {
        error("Could not Paused");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resume = async () => {
    try {
      const data = await resumeMintBurn();
      if (data.status) {
        sucess("Paused Successfully");
        const paused = await isContractPaused();
        setContractPaused(paused);
      } else {
        error("Could not Paused");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [sendertoken, setSendertoken] = useState("");
  const [tokenamount, setTokenamount] = useState(0);
  const [rescuetoken, setRescueToken] = useState("");

  const RescueToken = async () => {
    try {
      const data = await rescuToken(rescuetoken, sendertoken, tokenamount);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [GCSsupply, setGCSsupply] = useState(0);
  const [USDTsupply, setUSDTsupply] = useState(0);
  const [USDMsupply, setUSDMsupply] = useState(0);
  const [USDCsupply, setUSDCsupply] = useState(0);
  const [result, setResult] = useState(0);

  const Cal = async () => {
    console.log(usdmusdt, xau, gcstousd);
    const res =(Number(GCSsupply) * gcstousd +Number(USDTsupply) +Number(USDMsupply) * usdmusdt +Number(USDCsupply)) / Number(((xau / 31.1035) * 0.425 * 1.03).toFixed(5));
   
    setResult(res);
  };

  const [updatePauser, setUpdatePauser] = useState("");
  const [updateBlackLister, setUpdateBlackLister] = useState("");
  const [updateRescuer, setUpdateRescuer] = useState("");
  const [updateMasterMinter, setUpdateMasterMinter] = useState("");

  const updatePas = async () => {
    try {
      const data = await changePauser(updatePauser);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBlack = async () => {
    try {
      const data = await changeBlacklister(updateBlackLister);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRes = async () => {
    try {
      const data = await changeRescuer(updateRescuer);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateMaster = async () => {
    try {
      const data = await changeMasterMinter(updateMasterMinter);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [blacklist, setBlacklist] = useState("");
  const [unblacklist, setUnBlacklist] = useState("");

  const addtoblacklist = async () => {
    try {
      const data = await blacklistaddress(blacklist);
      if (data.status) {
        sucess("Blacklisted Successfully");
      } else {
        error("Could not Blacklist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removetoblacklist = async () => {
    try {
      const data = await unblacklistaddress(unblacklist);
      if (data.status) {
        sucess("Removed Successfully");
      } else {
        error("Could not Blacklist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [totalS, setTotalS] = useState(0);
  useEffect(() => {
    const inti = async () => {
      // const data = await Totak_Supply();

      // setTotalS(Number(data / 10 ** 18));

      const supply = await contract_BSC_MAAL_token_supply(USD_TEST_NET.Stable_Currency, USD_TEST_NET.Stable_Currency_maal)
      setTotalS(supply);
    };
    inti();
    setInterval(() => {
      inti();
    }, 5000);
  }, [account]);

  return (
    <div>
      <Container maxWidth="xl">
        <AdminNav />
        {/* CHART BOX OF ALL PRICE FOR XAUS */}
        <Box className="usdm-goldchainexcahnge-container">
          <Grid container spacing={1}>
            {/* ROW 1 */}

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  TOTAL SUPPLY : {Number(xausSupply).toFixed(0)} XAUS
                </h3>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  XAU TO USD: $ {xau.toFixed(7)}
                </h3>
              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  XAUS TO USDT: $ {Number(xaustousd).toFixed(7)}
                </h3>
              </Box>
            </Grid>

            {/* ROW 2 */}

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  GCS TO USDT: $ {gcstousd.toFixed(7)}
                </h3>
              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  XAUS TO GCS: {Number(xausgcs).toFixed(7)}
                </h3>
              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  XAUS TO USDM : {Number(xaususdm).toFixed(7)}
                </h3>
              </Box>
            </Grid>

            {/* ROW 3 */}

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  USDM TO USDT: $ {Number(usdmusdt).toFixed(7)}
                </h3>
              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  XAUS MARKETCAP: $
                  {Number(xausmk).toFixed(0)}
                 
                </h3>
              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">RESERVES: {reservGCS} GCS</h3>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* TABS SECTION FOR MINTER , MASTER MINTER , PAUSER, RESCUER, BLACKLIST, ONWER , CALCULATE */}

        <div className="adminsectiontabs">
          <Box sx={{ borderBottom: 1, borderColor: "black" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              id="flextabs"
              aria-label="basic tabs example"
            >
              <Tab label="MINTER" {...a11yProps(0)} id="tabsadmin" />
              <Tab label="MASTER MINTER" {...a11yProps(1)} id="tabsadmin" />
              <Tab label="PAUSER" {...a11yProps(2)} id="tabsadmin" />
              <Tab label="RESCUER" {...a11yProps(3)} id="tabsadmin" />
              <Tab label="BLACKLIST" {...a11yProps(4)} id="tabsadmin" />
              <Tab label="OWNER" {...a11yProps(5)} id="tabsadmin" />
              <Tab label="CALCULATOR" {...a11yProps(6)} id="tabsadmin" />
            </Tabs>
          </Box>

          {/* FOR MINTER TABLE */}

          <TabPanel value={value} index={0}>
            <Xausminterpage
              reservGCS={reservGCS}
              gcstousd={gcstousd}
              xau={xau}
              url={url}
              totalS={xausSupply}
              account={account}
            />
          </TabPanel>

          {/* FOR MASTER TABLE */}

          <TabPanel value={value} index={1}>
            <Xausmasterminter account={account} url={url}/>
          </TabPanel>

          {/* FOR PAUSER */}

          <TabPanel value={value} index={2}>
            <Container maxWidth="md">
              <Box className="masterminterarea">
                {/* <h6 className="notmasterminter">You are not Pauser</h6> */}
              </Box>
              <Box className="pauserarea">
                <Grid container spacing={2} className="d-flex justify-center">
                  <Grid item xl={6} lg={6} md={6}>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate"
                        onClick={() => pause()}
                      >
                        Pause
                      </button>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate"
                        onClick={() => resume()}
                      >
                        Resume
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </TabPanel>

          {/* RESCUER */}

          <TabPanel value={value} index={3}>
            <Container maxWidth="md">
              <Box className="masterminterarea">
                {/* <h6 className="notmasterminter">You are not Rescuer</h6> */}
              </Box>

              <Grid container spacing={2} className="d-flex justify-center">
                <Grid item xl={12} lg={12} md={12}>
                  <Box className="rescuerarea">
                    <Box className="rescuerbutton my-2">
                      <input
                        type="text"
                        placeholder="Token address"
                        className="rescuerinput"
                        value={rescuetoken}
                        onChange={(e) => setRescueToken(e.target.value)}
                      />
                    </Box>
                    <Box className="rescuerbutton">
                      <input
                        type="text"
                        placeholder="Reciver address"
                        className="rescuerinput"
                        value={sendertoken}
                        onChange={(e) => setSendertoken(e.target.value)}
                      />
                    </Box>
                    <Box className="rescuerbutton my-2">
                      <input
                        type="text"
                        placeholder="0"
                        className="rescuerinput"
                        value={tokenamount}
                        onChange={(e) => setTokenamount(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <button
                    className="buttoncalculate"
                    onClick={() => RescueToken()}
                  >
                    Rescue
                  </button>
                </Grid>
              </Grid>
            </Container>
          </TabPanel>

          {/* BLACKLIST */}

          <TabPanel value={value} index={4}>
            <Container maxWidth="md">
              {/* <Box className="masterminterarea">
                <h6 className="notmasterminter">You are not Blacklister</h6>
              </Box> */}
              <Box className="pauserarea">
                <Grid container spacing={2} className="d-flex justify-center">
                  <Grid item xl={6} lg={6} md={6}>
                    <Box>
                      <input
                        type="text"
                        placeholder="address"
                        className="rescuerinput"
                        value={blacklist}
                        onChange={(e) => setBlacklist(e.target.value)}
                      />
                    </Box>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate"
                        onClick={() => addtoblacklist()}
                      >
                        Blacklist
                      </button>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <Box>
                      <input
                        type="text"
                        placeholder="address"
                        className="rescuerinput"
                        value={unblacklist}
                        onChange={(e) => setUnBlacklist(e.target.value)}
                      />
                    </Box>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate2"
                        onClick={() => removetoblacklist()}
                      >
                        Remove from Blacklist
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </TabPanel>

          {/* OWNER */}

          <TabPanel value={value} index={5}>
            <Box className="calculate-prize mt-4">
              <Grid container spacing={2} className="d-flex justify-center">
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updatePauser}
                        onChange={(e) => setUpdatePauser(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="addminter">
                    <button
                      className="addminterbutton2"
                      onClick={() => updatePas()}
                    >
                      Set Pauser
                    </button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updateBlackLister}
                        onChange={(e) => setUpdateBlackLister(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="addminter">
                    <button
                      className="addminterbutton2"
                      onClick={() => updateBlack()}
                    >
                      Set Blacklist
                    </button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updateRescuer}
                        onChange={(e) => setUpdateRescuer(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="addminter">
                    <button
                      className="addminterbutton2"
                      onClick={() => updateRes()}
                    >
                      Set Rescuer
                    </button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updateMasterMinter}
                        onChange={(e) => setUpdateMasterMinter(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="pausebutton">
                    <button
                      className="addminterbutton2"
                      onClick={() => updateMaster()}
                    >
                      Set Master Minter
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          {/* FOR CALCULATE */}

          <TabPanel value={value} index={6}>
            <Box className="calculate-prize mt-4">
              <Grid container spacing={2} className="d-flex justify-center">
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>GCS Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="GCS"
                        value={GCSsupply}
                        onChange={(e) => setGCSsupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>USDM Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="USDM"
                        value={USDMsupply}
                        onChange={(e) => setUSDMsupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>USDT Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="USDT"
                        value={USDTsupply}
                        onChange={(e) => setUSDTsupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>USDC Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="USDC"
                        value={USDCsupply}
                        onChange={(e) => setUSDCsupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>
              </Grid>

              <div className="text-center mt-4 mb-3">
                <span className="fw-bold suggestionBurn p-2">
                  XAUS Supply allowed to be minted {Number(result).toFixed(5)}
                </span>
              </div>

              <Box className="calculate-button">
                <button className="buttoncalculate" onClick={() => Cal()}>
                  calculate
                </button>
              </Box>
            </Box>
          </TabPanel>
        </div>
      </Container>
    </div>
  );
}
