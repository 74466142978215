import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Container } from "@mui/system";
import { Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminNav from "../AdminNav";
import "./usdm.css";
import Usdmmintertable from "./Usdmmintertable";
import Usdmmasterminter from "./Usdmmasterminter";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import {
  Totak_Supply,
  PauseMintBurn,
  unPauseMintBurn,
  rescuToken,
  unblacklistaddress,
  blacklistaddress,
  changeRescuer,
  changePauser,
  changeBlacklister,
  changeMasterMinter,
} from "./Web3USDM/Methods";
import { contract_BSC_MAAL_token_supply } from "./../../Web3/Web3"
import { USD_TEST_NET } from "./Web3USDM/Address";

//BELOW FUNCTION IS FOR TABS THAT ARE STARTS FROM LINE NO. 124 MINTER , MASTER MINTER , PAUSER, RESCUER, BLACKLIST, ONWER , CALCULATE

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const url = "https://gcs.ap.ngrok.io/";

const error = (msg) => toast.error(msg);
const sucess = (msg) => toast.success(msg);

export default function Usdmpage({ account, url }) {
  // this state is for (MINTER , MASTER MINTER , PAUSER, RESCUER, BLACKLIST, ONWER , CALCULATE) these tabs
  const [value, setValue] = React.useState(0);

  // STATES FOR USDM RESCUER TAB
  const [rescuertokenaddress, setRescuerTokenAddress] = useState();
  const [rescuerreciveraddress, setRescuerReciverAddress] = useState();
  const [resucervalue, setRescuerValue] = useState(0);

  // STATES FOR USDM BLACKLIST TAB

  const [addblacklistaddress, setAddBlackListAddress] = useState();
  const [removeblacklisaddress, setRemoveBlackListAddress] = useState();

  // STATES FOR USDM ONWER TAB

  const [pauseraddress, setPauserAddress] = useState();

  const [rescueraddress, setRescuerAddress] = useState();
  const [masterminteraddress, setMasterMinterAddress] = useState();

  // STATES FOR USDM CALCULATOR

  const [gcssupply, setGcsSupply] = useState(0);
  const [usdtsupply, setUsdtSupply] = useState(0);
  const [usdcsupply, setUsdcSupply] = useState(0);
  const [result, setResult] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [gcsusdm, setGcsusdm] = useState(0);
  const [gcsusd, setGcsusd] = useState(0);
  const [lastmmk, setLaskMMK] = useState(0);
  const [currentmmk, setCurrentMMK] = useState(0);
  const [currentmmkforDisplay, setCurrentMMKForDisplay] = useState(0);
  const [totalS, setTotalS] = useState(0);
  const [usdmusdt, setUsdmusdt] = useState(0);
  const [addcurrentmmk, setAddcurrentmmk] = useState(0);
  const [govt, setGOVT] = useState(0);

  const [rescuetoken, setRescueToken] = useState("");
  const [sendertoken, setSendertoken] = useState("");
  const [tokenamount, setTokenamount] = useState(0);

  const [blacklist, setBlacklist] = useState("");
  const [unblacklist, setUnBlacklist] = useState("");

  const [updatePauser, setUpdatePauser] = useState("");
  const [updateRescuer, setUpdateRescuer] = useState("");
  const [updateBlackLister, setUpdateBlackLister] = useState("");
  const [updateMasterMinter, setUpdateMasterMinter] = useState("");
  const [addjustValue, setAddjustValue] = useState()

  const [gcstotalsupplymaalbsc, setGcstotalsupplymaalbsc] = useState()

  useEffect(() => {
    const init = async () => {
      // const data = await Totak_Supply();
      // setTotalS(data/10**18);
      const supply = await contract_BSC_MAAL_token_supply(USD_TEST_NET.Stable_Currency, USD_TEST_NET.Stable_Currency_maal)
      setTotalS(supply-9000000);

      const gcssupply = await contract_BSC_MAAL_token_supply(USD_TEST_NET.gcsStable_Currency, USD_TEST_NET.gcsStable_Currency_maal)
      setGcstotalsupplymaalbsc(gcssupply);

      axios
        .get(`${url}/gcsdetails`)
        .then((res) => {
          // res.data.reverse();
          // console.log(res.data[0]);
          setGcsusd(Number(res.data.GCS.GCSTOUSDT));
          setGcsusdm(Number(res.data.GCS.GCSTOUSDM));
          setUsdmusdt(Number(res.data.XAUS.USDM2USDT));
          setAddcurrentmmk(Number(res.data.MMK.ADDJUSTMMK));
          setCurrentMMKForDisplay(Number(res.data.MMK.MMKprice))
          setAddjustValue(Number(res.data.MMK.addjusted))
          setGOVT(Number(res.data.MMK.GOVT));
        })
        .catch(console.error);

      axios
        .get(`${url}/getdiffprice`)
        .then((res) => {
          res.data.reverse();
          setCurrentMMK(res.data[0].mmkPriceDiff);
          setLaskMMK(res.data[1].mmkPriceDiff);
        })
        .catch(console.error);
    };
    init();
  }, []);

  const pause = async () => {
    try {
      const data = await PauseMintBurn();
      if (data.status) {
        sucess("Paused Successfully");
        // const paused = await isContractPaused();
        // setContractPaused(paused);
      } else {
        error("Could not Paused");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unpause = async () => {
    try {
      const data = await unPauseMintBurn();
      if (data.status) {
        sucess("Paused Successfully");
        // const paused = await isContractPaused();
        // setContractPaused(paused);
      } else {
        error("Could not Paused");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const RescueToken = async () => {
    try {
      const data = await rescuToken(rescuetoken, sendertoken, tokenamount);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addtoblacklist = async () => {
    try {
      const data = await blacklistaddress(blacklist);
      if (data.status) {
        sucess("Blacklisted Successfully");
      } else {
        error("Could not Blacklist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removetoblacklist = async () => {
    try {
      const data = await unblacklistaddress(unblacklist);
      if (data.status) {
        sucess("Removed Successfully");
      } else {
        error("Could not Blacklist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRes = async () => {
    try {
      const data = await changeRescuer(updateRescuer);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePas = async () => {
    try {
      const data = await changePauser(updatePauser);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBlack = async () => {
    try {
      const data = await changeBlacklister(updateBlackLister);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateMaster = async () => {
    try {
      const data = await changeMasterMinter(updateMasterMinter);
      if (data.status) {
        sucess("Successfully");
      } else {
        error("Failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calsy = async () => {
    const res =
      (Number(gcssupply) * gcsusd + Number(usdtsupply) + Number(usdcsupply)) /
      usdmusdt;
    console.log(res);
    setResult(res);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <AdminNav />
        {/* CHART BOX OF ALL PRICE FOR USDM */}
        <Box className="usdm-goldchainexcahnge-container">
          <Grid container spacing={1}>
            {/* ROW 1 */}

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  TOTAL SUPPLY : {Number(totalS).toFixed(0)} USDM
                </h3>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  GCS TO USDT: ${Number(gcsusd).toFixed(7)}
                </h3>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  GCS MARKET CAP: ${(Number(gcsusd) * 5000000).toFixed(0)}
                </h3>
              </Box>
            </Grid>

            {/* ROW 2 */}

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">USD TO MMK (GOVT.): 2100</h3>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  MMK BINANCE P2P PRICE: {Number(currentmmkforDisplay).toFixed(0)}
                </h3>
              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  GCS TO USDM: {Number(gcsusdm).toFixed(7)}
                </h3>
              </Box>
            </Grid>

            {/* ROW 3 */}

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  USDM TO USDT: ${Number(usdmusdt).toFixed(7)}
                </h3>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">
                  USDM MARKET CAP: ${Number(755030 * gcsusd).toFixed(0)}
                </h3>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Box className="totalsupply">
                <h3 className="totalsupplystyle">GCS RESERVES: $755030</h3>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  Effective MMK Rate Section */}

        <Box className="effectivemmkrate">
          {/* <h5 className="effectivemmkrate-heading">
            Effective MMK Rate:{" "}
            {(
              (1 - Number(gcsusd / gcsusdm)) * Number(addcurrentmmk) +  govt).toFixed(0)}
          </h5> */}
          <h5 className="effectivemmkrate-heading">
            Effective MMK Rate:{" "}
            {
              currentmmkforDisplay + addjustValue
             
            }
          </h5>
        </Box>

        {/* TABS SECTION FOR MINTER , MASTER MINTER , PAUSER, RESCUER, BLACKLIST, ONWER , CALCULATE */}

        <Box className="adminsectiontabs">
          <Box sx={{ borderBottom: 1, borderColor: "black" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              id="flextabs"
              aria-label="basic tabs example"
            >
              <Tab label="MINTER" {...a11yProps(0)} id="tabsadmin" />
              <Tab label="MASTER MINTER" {...a11yProps(1)} id="tabsadmin" />
              <Tab label="PAUSER" {...a11yProps(2)} id="tabsadmin" />
              <Tab label="RESCUER" {...a11yProps(3)} id="tabsadmin" />
              <Tab label="BLACKLIST" {...a11yProps(4)} id="tabsadmin" />
              <Tab label="OWNER" {...a11yProps(5)} id="tabsadmin" />
              <Tab label="CALCULATOR" {...a11yProps(6)} id="tabsadmin" />
            </Tabs>
          </Box>

          {/* FOR MINTER TABLE */}

          <TabPanel value={value} index={0}>
            <Usdmmintertable
              url={url}
              gcsusdm={gcsusdm}
              gcsusd={gcsusd}
              lastmmk={lastmmk}
              currentmmk={currentmmk + addjustValue}
              totalS={totalS}
              account={account}
            />
          </TabPanel>

          {/* FOR MASTER TABLE */}

          <TabPanel value={value} index={1}>
            <Usdmmasterminter
              url={url}
              gcsusdm={gcsusdm}
              gcsusd={gcsusd}
              lastmmk={lastmmk}
              currentmmk={currentmmk + addjustValue}
              account={account}
            />
          </TabPanel>

          {/* FOR PAUSER */}

          <TabPanel value={value} index={2}>
            <Container maxWidth="md">
              {/* <Box className="masterminterarea">
                <h6 className="notmasterminter">You are not Master Minter</h6>
              </Box> */}
              <Box className="pauserarea">
                <Grid container spacing={2} className="d-flex justify-center">
                  <Grid item xl={6} lg={6} md={6}>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate"
                        onClick={() => pause()}
                      >
                        Pause
                      </button>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate"
                        onClick={() => unpause()}
                      >
                        Resume
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </TabPanel>

          {/* RESCUER */}

          <TabPanel value={value} index={3}>
            <Container maxWidth="md">
              {/* <Box className="masterminterarea">
                <h6 className="notmasterminter">You are not Master Minter</h6>
              </Box> */}

              <Grid container spacing={2} className="d-flex justify-center">
                <Grid item xl={12} lg={12} md={12}>
                  <Box className="rescuerarea">
                    <Box className="rescuerbutton my-2">
                      <input
                        type="text"
                        placeholder="Token address"
                        className="rescuerinput"
                        onChange={(e) => setRescueToken(e.target.value)}
                      />
                    </Box>
                    <Box className="rescuerbutton">
                      <input
                        type="text"
                        placeholder="Reciver address"
                        className="rescuerinput"
                        onChange={(e) => setSendertoken(e.target.value)}
                      />
                    </Box>
                    <Box className="rescuerbutton my-2">
                      <input
                        type="text"
                        placeholder="0"
                        className="rescuerinput"
                        onChange={(e) => setTokenamount(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <button
                    className="buttoncalculate"
                    onClick={() => RescueToken()}
                  >
                    Rescue
                  </button>
                </Grid>
              </Grid>
            </Container>
          </TabPanel>

          {/* BLACKLIST */}

          <TabPanel value={value} index={4}>
            <Container maxWidth="md">
              {/* <Box className="masterminterarea">
                <h6 className="notmasterminter">You are not Master Minter</h6>
              </Box> */}
              <Box className="pauserarea">
                <Grid container spacing={2} className="d-flex justify-center">
                  <Grid item xl={6} lg={6} md={6}>
                    <Box>
                      <input
                        type="text"
                        placeholder="address"
                        className="rescuerinput"
                        onChange={(e) => setBlacklist(e.target.value)}
                      />
                    </Box>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate"
                        onClick={() => addtoblacklist()}
                      >
                        Blacklist
                      </button>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <Box>
                      <input
                        type="text"
                        placeholder="address"
                        className="rescuerinput"
                        onChange={(e) => setUnBlacklist(e.target.value)}
                      />
                    </Box>
                    <Box className="pausebutton">
                      <button
                        className="buttoncalculate2"
                        onClick={() => removetoblacklist()}
                      >
                        Remove from Blacklist
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </TabPanel>

          {/* OWNER */}

          <TabPanel value={value} index={5}>
            <Box className="calculate-prize mt-4">
              <Grid container spacing={2} className="d-flex justify-center">
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updatePauser}
                        onChange={(e) => setUpdatePauser(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="addminter">
                    <button
                      className="addminterbutton2"
                      onClick={() => updatePas()}
                    >
                      Set Pauser
                    </button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updateBlackLister}
                        onChange={(e) => setUpdateBlackLister(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="addminter">
                    <button
                      className="addminterbutton2"
                      onClick={() => updateBlack()}
                    >
                      Set Blacklist
                    </button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updateRescuer}
                        onChange={(e) => setUpdateRescuer(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="addminter">
                    <button
                      className="addminterbutton2"
                      onClick={() => updateRes()}
                    >
                      Set Rescuer
                    </button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Control
                        type="text"
                        placeholder="address"
                        value={updateMasterMinter}
                        onChange={(e) => setUpdateMasterMinter(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Box className="pausebutton">
                    <button
                      className="addminterbutton2"
                      onClick={() => updateMaster()}
                    >
                      Set Master Minter
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          {/* FOR CALCULATE */}

          <TabPanel value={value} index={6}>
            <Box className="calculate-prize mt-4">
              <Grid container spacing={2} className="d-flex justify-center">
                <Grid item xl={4} lg={4}>
                  <Form>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>GCS Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="GCS"
                        value={gcssupply}
                        onChange={(e) => setGcsSupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>

                <Grid item xl={4} lg={4}>
                  <Form>
                    <Form.Group className="mb-4" controlId="formBasictext">
                      <Form.Label>USDT Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="USDT"
                        value={usdtsupply}
                        onChange={(e) => setUsdtSupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>
                <Grid item xl={4} lg={4}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>USDC Supply</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="USDC"
                        value={usdcsupply}
                        onChange={(e) => setUsdcSupply(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid>
              </Grid>

              {result > 0 ? (
                <div className="mt-5 text-center mb-5">
                  <p className="text-ligt">
                    Supply allowed to be minted {Number(result).toFixed(5)}
                  </p>
                </div>
              ) : (
                ""
              )}

              <Box className="calculate-button">
                <button className="buttoncalculate" onClick={() => calsy()}>
                  calculate
                </button>
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Container>
    </div>
  );
}
