import { GetChainId, getContract, getUserAddress, towie } from "./Function";
import { USDm } from "./ABI";
import { USD_TEST_NET } from "./Address";

export const Totak_Supply = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.totalSupply().call();
    // console.log("contract",contract)
    return Number(data);
  } catch (error) {
    console.log(error);
  }
};

export const getOwner = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.owner().call();
    return data;
  } catch (error) {}
};

export const changeMasterMinter = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .updateMasterMinter(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const changeRescuer = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .updateRescuer(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const changePauser = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      ._updatePauser(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const changeBlacklister = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .updateBlacklister(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getMasterminter = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.masterMinter().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const isContractPaused = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.paused().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPauser = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.pauser().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getRescuer = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.rescuer().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getBlacklister = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.blacklister().call();
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const MintByMaster = async (amount) => {
  try {
    const a = await towie(amount);
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .mintByMaster(await getMasterminter(), a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const PauseMintBurn = async (amount) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      ._pause()
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const resumeMintBurn = async (amount) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      ._unpause()
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const blacklistaddress = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .blacklist(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const unblacklistaddress = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .unBlacklist(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const rescuToken = async (address, to, amount) => {
  try {
    const a = await towie(amount);
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .rescueERC20(address, to, a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Mint = async (amount) => {
  try {
    const a = await towie(amount);
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .mint(await getMasterminter(), a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Burn = async (amount) => {
  let b;
  try {
    b = Math.abs(amount);
    const a = await towie(b);
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .burn(a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Add_Minter = async (address, amount) => {
  try {
    const a = await towie(amount);
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .configureMinter(address, a)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Remove_Minter = async (address) => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .removeMinter(address)
      .send({ from: await getUserAddress() });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Checking_Minter = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods.isMinter(await getUserAddress()).call();
    console.log("Am I minter ? ", data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Checking_Balance = async () => {
  try {
    const id = await GetChainId()
    const contract = await getContract(USDm, id == 56 ? USD_TEST_NET.Stable_Currency : id == 786 ? USD_TEST_NET.Stable_Currency_maal : USD_TEST_NET.Stable_Currency_maal);
    const data = await contract.methods
      .balanceOf(await getUserAddress())
      .call();
    return Number(data);
  } catch (error) {
    console.log(error);
  }
};
