import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { getAdmin, GetChainId, webProvide } from "../../Web3/Web3";
import {
  BsTelegram,
  BsFacebook,
  BsTwitter,
  BsDiscord,
  BsLinkedin,
  BsInstagram,
} from "react-icons/bs";
import "./Navbar2.css";
import { FaBars } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import meta from "./img/meta.png";
import connect from "./img/connect.dc871f60434bd877387be88f4a3192d6.svg";
{
  /* import { GetChainId } from "../../../../platformgctech-v3-main/src/compnents/pages/connectionswallet"; */
}

function Navbar2({
  Metamask,
  account,
  contractadmin,
  Dissconnect,
  walletConnectlogin,
}) {
  let web3 = webProvide();
  const [show, setShow] = useState(false);
  const [network, setNetwork] = useState();
  const [active, setActive] = useState(1);
  useEffect(() => {
    const init = async () => {
      const id = await GetChainId();
      // console.log("Chain ID is ", id);
    };
    init();
  }, [account]);

  const slicewallet = (add) => {
    const first = add.slice(0, 5);
    const second = add.slice(37);
    return first + "..." + second;
  };
  const switchnetwork = async (id) => {
    await web3.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${id}` }], // chainId must be in hexadecimal numbers
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            {" "}
            <img
              src="https://cdn2.bttcdn.com/2e4add5483abf2ff/e2bc39a2d59c627c24ff83406d75d1a6.png"
              alt=""
              width="150px"
            />
          </a>
          <a
            class="btn btn-primary icon-fa"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
            style={{ padding: "10px 30px !important" }}
          >
            <FaBars />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="https://www.gcex.lt/Trade "
                  target="_blank"
                >
                  Trade
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.gcex.lt/register?redirect=/Wallet"
                  target="_blank"
                >
                  Wallet
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link "
                  href="https://www.gcex.lt/register?redirect=/Orders"
                  target="_blank"
                >
                  Order
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link " href="">
                  <Link
                    id="link"
                    onClick={() => setActive(1)}
                    to="/"
                    className={window.location.pathname === "/" ? "active" : ""}
                  >
                    Swap
                  </Link>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link " href="">
                  <Link
                    id="link"
                    onClick={() => setActive(3)}
                    to="/staking"
                    className={
                      window.location.pathname === "/staking" ? "active" : ""
                    }
                  >
                    Staking
                  </Link>
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link "
                  href="https://nft.gcex.lt/"
                  target="_blank"
                >
                  NFT
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link "
                  href="https://www.gcex.lt/ieo"
                  target="_blank"
                >
                  IEO
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link "
                  href="https://www.goldchainex.com/"
                  target="_blank"
                >
                  GC Group
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link "
                  href="https://www.goldchainex.com/news"
                  target="_blank"
                >
                  Featured Press
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link "
                  href="https://trade.theunitedcapital.com/"
                  target="_blank"
                >
                  Spot Trading
                </a>
              </li>
            </ul>
            <div className="ul-content" style={{ display: "flex" }}>
              <ul
                className="under"
                style={{ marginRight: "0rem", marginTop: "1rem" }}
              >
                <li className="list-item">
                  <a
                    href="https://t.me/GoldchainExchangeCommunity"
                    target="_blank"
                  >
                    <BsTelegram />
                  </a>
                </li>
                <li className="list-item">
                  <a href="https://facebook.com/" target="_blank">
                    <BsFacebook />
                  </a>
                </li>
                <li className="list-item">
                  <a href="https://twitter.com/GcexNft" target="_blank">
                    <BsTwitter />
                  </a>
                </li>
                <li className="list-item">
                  <a href="https://discord.gg/QNNZGD2Zfa" target="_blank">
                    <BsDiscord />
                  </a>
                </li>
                <li className="list-item">
                  <a href="https://instagram.com/gcexnft" target="_blank">
                    <BsInstagram />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              {/*          <Button
                variant="primary"
                className="btn-1"
                onClick={() => {
                  if (account) {
                    Dissconnect();
                    return true;
                  }
                  handleShow();
                }}
                style={{ padding: "7px 28px" }}
              >
                {account ? slicewallet(account) : "Connect Wallet"}
              </Button> */}
            </div>
          </div>
        </div>
      </nav>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="button-reset1 button-reset45"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="dropdown mt-3">
            {/*     <div className="offcanvas-button">
            
             <Button
              variant="primary"
              className="offcanvas-button1"
              onClick={() => {
                if (account) {
                  Dissconnect();
                  return true;
                }
                handleShow();
              }}
              style={{ padding: "7px 28px" }}
            >
              {account ? slicewallet(account) : "Connect Wallet"}
            </Button> 
     
            </div> */}
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://www.gcex.lt/Trade" target="_blank">
                  Trade
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a
                  href="https://www.gcex.lt/register?redirect=/Wallet"
                  target="_blank"
                >
                  Wallet
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a
                  href="https://www.gcex.lt/register?redirect=/Orders"
                  target="_blank"
                >
                  Order
                </a>
              </button>
            </div>

            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <Link
                  id="link"
                  onClick={() => setActive(1)}
                  to="/"
                  className={window.location.pathname === "/" ? "active" : ""}
                >
                  Swap
                </Link>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <Link
                  id="link"
                  onClick={() => setActive(3)}
                  to="/staking"
                  className={
                    window.location.pathname === "/staking" ? "active" : ""
                  }
                >
                  Staking
                </Link>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://nft.gcex.lt/" target="_blank">
                  NFT
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://www.gcex.lt/ieo" target="_blank">
                  IEO
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://www.goldchainex.com/" target="_blank">
                  GC Group
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://www.goldchainex.com/news" target="_blank">
                  Featured Press
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://trade.theunitedcapital.com/" target="_blank">
                  Spot Trading
                </a>
              </button>
            </div>
            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://trade.theunitedcapital.com/" target="_blank">
                  Logout
                </a>
              </button>
            </div>

            <div className="offcanvas-button">
              {(account && account == contractadmin) ||
              account == "0xD9E0f712652589584B035Db1cb44A79F2eA2389F" ||
              account == "0x149b65e2EB31c196F9C2407E0A88a9cF1F71bd35" ||
              account == "0xdBd21416Da1207Bfb66BDf3baBE16538f112b706" ||
              account == "0x999619733a2df505229967Db54b84D6F4709DC45" ||
              account == "0x8A25ea29363894eAcf4f0ef1Bfe0cAfc75a224FF" ||
              account == "0x9929BbE55e79cAC1003Dc4c9cD2e911CbaAd532D" ? (
                <>
                  <button className="offcanvas-button2">
                    <Link
                      onClick={() => setActive(2)}
                      id="offcanvas-button2"
                      to="/admin"
                      className={
                        window.location.pathname === "/admin" ? "active" : ""
                      }
                    >
                      ADMIN
                    </Link>
                  </button>
                </>
              ) : (
                ""
              )}
              <Button
                className="connectButton2 mx-3 "
                id="offcanvas-button5"
                variant="primary"
                onClick={() => {
                  if (account) {
                    Dissconnect();
                    return true;
                  }
                  handleShow();
                }}
              >
                {account ? slicewallet(account) : "Connect Wallet"}
              </Button>
            </div>

            <div className="offcanvas-button">
              <button className="offcanvas-button2">
                <a href="https://trade.theunitedcapital.com/">BUY GCEX</a>
              </button>
            </div>
            <div className="offcanvas-button">
              <div className="dropdown ">
                <button
                  className="dropdown-toggle "
                  type="button"
                  id="dropdownMenuButton2"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  {network ? network : "NETWORKS"}
                </button>
                <ul
                  className="dropdown-menu disconnect1"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li className="network" onClick={() => switchnetwork(1)}>
                    Ethereum
                  </li>
                  <li className="network" onClick={() => switchnetwork(38)}>
                    Binance Smart Chain
                  </li>
                  <li className="network" onClick={() => switchnetwork(312)}>
                  Maalscan
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="text-center">Please connect your wallet</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-around">
              <img
                src={meta}
                alt=""
                className="wallet-img"
                onClick={() => {
                  Metamask();
                  setShow(!show);
                }}
              />
              <img
                src={connect}
                alt=""
                className="wallet-img2"
                onClick={() => {
                  walletConnectlogin();
                  setShow(!show);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Navbar2;
