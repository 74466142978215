import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import {
  Totak_Supply,
  Burn,
  MintByMaster,
  Add_Minter,
  Remove_Minter,
  getMasterminter,
} from "./Web3USDM/Methods";
import axios from "axios";

const columns = [
  { id: "name", label: "Type", minWidth: 170, align: "center" },
  { id: "code", label: "Amount", minWidth: 100, align: "center" },
  {
    id: "population",
    label: "Time",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Hash",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData(
    "Mint",
    1623534.4865198713,
    "8/30/2022, 11:19:53 AM",
    "0xd0c445cd9e06911e299239464531de5dad71c7849f5ef9d8cbc4a6854dc87d8d"
  ),
  createData(
    "Burn",
    -76299.59195874631,
    "8/31/2022, 10:23:36 PM",
    "0xb5c0c93ca223388f4531344470a5fbb8f851ee75144463c3c490be4bb2655fe2"
  ),
];

const error = (msg) => toast.error(msg);
const sucess = (msg) => toast.success(msg);
const onlne_database_id = "638399ee909d13605911b5c4"; //for online data base

export default function Usdmmasterminter({ url }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usdmmint, setUsdmMint] = React.useState(0);
  const [usdmburn, setUsdmBurn] = React.useState(0);
  const [addusdmminteraddress, setAddUsdmMinterAddress] = React.useState();
  const [addusdmmintervalue, setAddUsdmMinterValue] = React.useState(0);
  const [removeusdmminteraddress, setRemoveUsdmMinterAddress] =
    React.useState();
  const [adjustusdmmmk, setAdjustUsdmMmk] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [mintamount, setMintamount] = useState(0);
  const [burnamount, setBurnAmount] = useState(0);
  const [addminteraddress, setAddminteraddress] = useState("");
  const [addminteramount, setAddminteramount] = useState(0);
  const [removeminteraddress, setRemoveminteraddress] = useState("");
  const [newMMK, setNewMMK] = useState(0);
  const [govtchange, setGovtchange] = useState(0)
  const [masterminterm, setMasterMinter] = useState("");

  useEffect(() => {
    const init = async () => {
      getTabledata();
      const masterminter = await getMasterminter();
      setMasterMinter(masterminter);
    };
    init();
  }, []);

  const MasterMinting = async (amount) => {
    try {
      const data1 = await MintByMaster(amount);
      console.log(data1);
      if (data1.status) {
        // const data = await Totak_Supply();
        sucess("Token Minted Successfully");
        dataFill("Mint", amount, data1.transactionHash);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Burn_token = async (amount) => {
    try {
      const data1 = await Burn(amount);
      console.log(data1);
      if (data1.status) {
        // const data = await Totak_Supply();
        sucess("Token Burned Successfully");
        dataFill("Burn", amount, data1.transactionHash);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dataFill = async (method, amount, hash) => {
    var d = new Date().toLocaleString();
    axios
      .post(`${url}/makehistory`, {
        id: 0,
        Method: method,
        Amount: amount,
        Date: d,
        Hash: hash,
      })
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const add_minter = async () => {
    try {
      const data = await Add_Minter(addminteraddress, addminteramount);
      if (data.status) {
        sucess("Minter Added Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const remove_minter = async () => {
    try {
      const data = await Remove_Minter(removeminteraddress);
      if (data.status) {
        sucess("Minter Removed Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNewMMK = async (data) => {
    try {
      axios
        .put(`${url}/valuechange`, {
          id: onlne_database_id,
          addMMk: data,
        })
        .then(async (res) => {
          console.log(res);
          sucess("Adjusted Successfull");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const updateGOVT = async() =>{
    try {
      axios
        .put(`${url}/valuechange`, {
          id: onlne_database_id,
          govt: govtchange,
        })
        .then(async (res) => {
          console.log(res);
          sucess("Adjusted Successfull");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }


  const [tabledata, setTableData] = useState([]);

  const getTabledata = async () => {
    axios
      .post(`${url}/historybyid`, {
        id: 0,
      })
      .then((res) => {
        console.log(res);
        res.data.reverse();
        setTableData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Container maxWidth="md">
        {" "}
        <Box className="masterminterarea">
          {/* <h6 className="notmasterminter">You are not Master Minter</h6> */}
        </Box>
        <Grid container spacing={2} className="d-flex justify-center">
          <Grid item xl={3} lg={3} md={3} sm={6}>
            <Box>
              {" "}
              <input
                type="text"
                placeholder="0"
                value={mintamount}
                onChange={(e) => setMintamount(e.target.value)}
              />
            </Box>
            <Box className="addminter">
              <button
                className="className-mint3"
                onClick={() => MasterMinting(mintamount)}
              >
                Master Mint
              </button>
            </Box>
            <Box>
              {" "}
              <input
                type="text"
                placeholder="0"
                value={burnamount}
                onChange={(e) => setBurnAmount(e.target.value)}
              />
            </Box>
            <Box className="addminter">
              <button
                className="className-burn2"
                onClick={() => Burn_token(burnamount)}
              >
                Burn
              </button>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6}>
            <Box>
              {" "}
              <input
                type="text"
                placeholder="address"
                value={addminteraddress}
                onChange={(e) => setAddminteraddress(e.target.value)}
              />
            </Box>
            <Box>
              {" "}
              <input
                placeholder="0"
                classaName="my-2"
                value={addminteramount}
                onChange={(e) => setAddminteramount(e.target.value)}
              />
            </Box>
            <Box className="addminter">
              <button className="addminterbutton" onClick={() => add_minter()}>
                Add Minter
              </button>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6}>
            <Box>
              {" "}
              <input
                type="text"
                placeholder="address"
                value={removeminteraddress}
                onChange={(e) => setRemoveminteraddress(e.target.value)}
              />
            </Box>
            <Box className="addminter">
              <button
                className="addminterbutton2"
                onClick={() => remove_minter()}
              >
                Remove Minter
              </button>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6}>
            <Box>
              {" "}
              <input
                type="text"
                placeholder="address"
                value={newMMK}
                onChange={(e) => setNewMMK(e.target.value)}
              />
            </Box>
            <Box className="addminter">
              <button
                className="addminterbutton2"
                onClick={() => updateNewMMK(newMMK)}
              >
                Adjust MMK
              </button>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6}>
            <Box>
              {" "}
              <input
                type="text"
                placeholder="address"
                value={govtchange}
                onChange={(e) => setGovtchange(e.target.value)}
              />
            </Box>
            <Box className="addminter">
              <button
                className="addminterbutton2"
                onClick={() => updateGOVT(newMMK)}
              >
                Adjust GOVT
              </button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabledata &&
              tabledata.map((res) => {
                return (
                  <TableRow hover role="checkbox">
                    <TableCell>{res.Method}</TableCell>
                    <TableCell>{res.Amount}</TableCell>
                    <TableCell>{res.Date}</TableCell>
                    <TableCell>{res.Hash}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Toaster />
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
