import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container } from "@mui/system";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function Stakingrefferal({ account, url }) {
  const { user } = useParams();
  const [ref, setRef] = useState([]);
  console.log(account);
  useEffect(() => {
    const init = async () => {
      axios
        .post(`${url}/isuser`, {
          user: user.toLowerCase(),
        })
        .then((res) => {
          console.log(res);
          setRef(res.data[0].refferals);
        });
    };
    init();
  }, [account]);
  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: "50px" }}>
        <Box sx={{ marginBottom: "20px", fontWeight: "500" }}>
          Second Level Refferal ({user})
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.N0</TableCell>
                <TableCell align="center">Wallet Address</TableCell>
                <TableCell align="center">TX</TableCell>
                <TableCell align="center">Staked amount</TableCell>
                <TableCell align="center">Plan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ref &&
                ref.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {ref.indexOf(row)}
                    </TableCell>
                    <TableCell align="center">{row.wallet}</TableCell>
                    <TableCell align="center">{row.tx}</TableCell>
                    <TableCell align="center">
                      {row.amount} {row.symbol}
                    </TableCell>
                    <TableCell align="center">{row.plan}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
